
import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { Link } from "react-router-dom";
import logo from '../../assets/logo-art-big.png';
import logoCollapsed from '../../assets/logo-cpa2.png';
import './AppLeftMenu.scss';
import AuthenticationHelper from '../../../../shared/helpers/authenticationHelper';
import UsuariosApi from '../../../usuarios/api/UsuariosApi';
import Icon, { DatabaseOutlined, LaptopOutlined, UserOutlined, QuestionOutlined } from "@ant-design/icons";

const { Sider } = Layout;
const { SubMenu } = Menu;
const MenuItem = Menu.Item;

const AppLeftMenu = () => {

  const [collapsed, onCollapse] = useState(false);
  const [tipoUsuario, setTipoUsuario] = useState();

  function viewMenu() {
    UsuariosApi.TipoUsuario(AuthenticationHelper.getId().toString()).then(response => { setTipoUsuario(response); });
    if (tipoUsuario !== "Operador") {
      return [
        { key: '1', icon: LaptopOutlined, link: '/presentaciones', title: 'Presentaciones' },
        { key: '2', icon: DatabaseOutlined, link: '/referencias', title: 'Referencias' },
        { key: '3', icon: UserOutlined, link: '/auth/ticket/usuarios', title: 'Usuarios' },
        { key: '4', icon: QuestionOutlined, link: '/ayuda', title: 'Ayuda' }
      ]
    } else {
      return [
        { key: '1', icon: LaptopOutlined, link: '/presentaciones', title: 'Presentaciones' },
        { key: '2', icon: DatabaseOutlined, link: '/referencias', title: 'Referencias' },
        { key: '3', icon: QuestionOutlined, link: '/ayuda', title: 'Ayuda' }
      ]
    }
  }

  const menuitems = viewMenu();

  function renderMenuItem(item) {
    if (item.item && item.length > 0) {
      return <SubMenu key={item.key} icon={<Icon component={item.icon} />} title={item.title}>
        {item.item.map(child => renderMenuItem(child))}
      </SubMenu>;
    }
    else {
      return <MenuItem key={item.key} icon={<Icon component={item.icon} />}>
        <Link to={item.link} /><span>{item.title}</span>
      </MenuItem>
    }
  }

  return (
    <>
      <Sider className="left-menu-main" collapsible collapsed={collapsed} onCollapse={onCollapse}>
        <div className="app-layout-logo" >
          {
            collapsed
              ? <img src={logoCollapsed} alt="logo" className="logoImg" style={{ width: 64 }} />
              : <img src={logo} alt="logo" className="logoImg" style={{ width: 150, height: 70 }} />
          }
        </div>

        <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
          {menuitems.map(item => renderMenuItem(item))}
        </Menu>
      </Sider>
    </>
  );
}

export default AppLeftMenu;
