import React from "react";
import { Form, Input } from "antd";

const FormItem = Form.Item;

const DniFormItem = (props) => {
    return (
        <FormItem
          { ...props }
          
          rules={ [...props.rules, { 
            validator(_, value) {
              const reg = /^\d{8}(?:[-\s]\d{4})?$/;
              if(value.length > 0 && value.length < 7){ return Promise.reject(new Error('El DNI debe tener 7 o mas numeros')) }
              return (!isNaN(value) && reg.test(value)) || value === '' ? Promise.resolve() : Promise.reject(new Error('El DNI debe tener solo numeros'));
            } 
          } ] }
        >
          <Input maxLength={ 8 } style={ { width: '100%' } }/>
        </FormItem>
      );
}
    
export default DniFormItem;