import Request from "../../../shared/helpers/request";
import Utils from "../../../shared/helpers/utils";

const ReferenciasApi = {
  GetListado: () => {
    return Request
      .get('referencias/listado')
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },
  GetTabla: tabla => {
    if(sessionStorage[tabla]){
      return Promise.resolve(JSON.parse(sessionStorage[tabla]));
    }

    return Request
      .get('referencias/tabla?tabla=' + tabla)
      .then((response) => {
        let arr = Utils.getReferenciaArray(response.data);
        sessionStorage.setItem(tabla, JSON.stringify(arr));
        return arr;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }
}

export default ReferenciasApi;