import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import { Route } from "react-router-dom";
import AyudaView from "./components/AyudaView";
 
const AyudaPage = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/`} component={ AyudaView } />
    </Switch>
  );
}

export default AyudaPage