import React, { useEffect, useState } from "react";
import { Form, Input, Spin, Collapse, Modal, Tooltip, Checkbox, InputNumber, notification } from "antd";
import { PlusCircleTwoTone } from '@ant-design/icons';
import { FormConfig } from "../../../../shared/config/GlobalConfig";
import AnexoIPuestoModal from "./establecimientos_declarados/AnexoIPuestoModal";
import AnexoIPuestosTable from "../view/establecimientos_declarados/AnexoIPuestosTable";
import AnexoISectorModal from "./establecimientos_declarados/AnexoISectorModal";
import AnexoISectoresTable from "../view/establecimientos_declarados/AnexoISectoresTable";
import AnexoIResponsableModal from "./establecimientos_declarados/AnexoIResponsableModal";
import AnexoIResponsablesTable from "../view/establecimientos_declarados/AnexoIResponsablesTable";
import AnexoIContratistasTable from "../view/establecimientos_declarados/AnexoIContratistasTable";
import AnexoIContratistaModal from "./establecimientos_declarados/AnexoIContratistaModal";
import EstablecimientosApi from "../../../establecimientos/api/EstablecimientosApi";
import Utils from "../../../../shared/helpers/utils";
import EstablecimientoEmpresaSelectFormItem from "../../../../shared/components/establecimientoEmpresaSelectFormItem";
import CuitFormItem from '../../../../shared/components/cuitFormItem';
import CampoObligatorio from "../../../../shared/components/campoObligatorio";
import AuthenticationHelper from "../../../../shared/helpers/authenticationHelper";

const FormItem = Form.Item;
const { Panel } = Collapse;

const AnexoIEstablecimientoModal = props => {
  const [establecimientosEmpresa, setEstablecimientosEmpresa] = useState([]);
  const [puestos, setPuestos] = useState([]);
  const [sectores, setSectores] = useState([]);
  const [responsables, setResponsables] = useState([]);
  const [contratistas, setContratistas] = useState([]);
  const [showPuestoModal, setShowPuestoModal] = useState(false);
  const [showSectorModal, setShowSectorModal] = useState(false);
  const [showResponsableModal, setShowResponsableModal] = useState(false);
  const [showContratistaModal, setShowContratistaModal] = useState(false);
  const [selectedPuesto, setSelectedPuesto] = useState(undefined);
  const [selectedSector, setSelectedSector] = useState(undefined);
  const [selectedResponsable, setSelectedResponsable] = useState(undefined);
  const [selectedContratista, setSelectedContratista] = useState(undefined);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    EstablecimientosApi.GetEmpresasByCuit(props.cuit).then((response) => { setEstablecimientosEmpresa(response); })
  }, []);

  useEffect(() => {
    if (props.visible === true) {
      form.resetFields();
      setPuestos(props.establecimiento ? props.establecimiento.Puestos : []);
      setSectores(props.establecimiento ? props.establecimiento.Sectores : []);
      setResponsables(props.establecimiento ? props.establecimiento.Responsables : []);
      setContratistas(props.establecimiento ? props.establecimiento.Contratistas : []);
    }
  }, [props.visible]);

  const handleOk = () => {
    form.validateFields()
      .then(values => {
        props.onOk({
          Hash: props.establecimiento ? props.establecimiento.Hash : Utils.generateHash(),
          ...values,
          Puestos: puestos,
          Sectores: sectores,
          Responsables: responsables,
          Contratistas: contratistas,
        });
      })
      .catch(error => {
        notification.warning({ message: 'Error de validación', description: 'Revise los campos del formulario', placement: 'bottomRight' });
      });
  }

  return (
    <Modal
      title={`${props.establecimiento ? 'Editar' : 'Agregar'} Establecimiento`}
      width="80%"
      visible={props.visible}
      onOk={handleOk}
      onCancel={props.onCancel}
    >
      <Spin spinning={working}>
        <Form
          {...FormConfig.DefaultLayout}
          form={form}
          name="formulario"
          initialValues={{
            ...props.establecimiento,
            PermitidoFumar: props.establecimiento ? props.establecimiento.PermitidoFumar : false,
            LugaresCerradosParaFumar: props.establecimiento ? props.establecimiento.LugaresCerradosParaFumar : false,
          }}
          scrollToFirstError
        >
          <Collapse defaultActiveKey="1">
            <Panel header="Establecimiento" key="1">
              {props.establecimiento?.IdEstablecimientoDeclarado ? <FormItem name="IdEstablecimientoDeclarado" label="Establecimiento Declarado" rules={FormConfig.DefaultRules}>
                <Input disabled />
              </FormItem> : ''}

              <EstablecimientoEmpresaSelectFormItem name="IdEstablecimientoEmpresa" label="Establecimiento Empresa"
                rules={FormConfig.DefaultRules}
                establecimientosEmpresa={establecimientosEmpresa}
              />

              <FormItem name="DescripcionActividad" label="Descripcion Actividad" rules={FormConfig.DefaultRules}>
                <Input />
              </FormItem>

              <FormItem name="CantTrabEventualesProd" label="Cant Trab Eventuales Prod" rules={FormConfig.DefaultRules}>
                <InputNumber min={0} style={{ width: '100%' }} />
              </FormItem>

              <FormItem name="CantTrabEventualesAdmin" label="Cant Trab Eventuales Admin" rules={FormConfig.DefaultRules}>
                <InputNumber min={0} style={{ width: '100%' }} />
              </FormItem>

              <FormItem name="CantTrabPropiosProd" label="Cant Trab Propios Prod" rules={FormConfig.DefaultRules}>
                <InputNumber min={0} style={{ width: '100%' }} />
              </FormItem>

              <FormItem name="CantTrabPropiosAdmin" label="Cant Trab Propios Admin" rules={FormConfig.DefaultRules}>
                <InputNumber min={0} style={{ width: '100%' }} />
              </FormItem>

              <FormItem name="Mail" label="Mail" rules={[...FormConfig.DefaultRules, { type: 'email', message: 'Debe ingresar una dirección de email valida' }]}>
                <Input />
              </FormItem>

              <FormItem name="Telefono" label="Telefono" rules={FormConfig.DefaultRules}>
                <Input />
              </FormItem>

              <CuitFormItem name="CuilContacto" label="Cuil Contacto" rules={[...FormConfig.DefaultRules]} />

              <FormItem name="PermitidoFumar" label="Permitido Fumar" valuePropName="checked">
                <Checkbox />
              </FormItem>

              <FormItem name="LugaresCerradosParaFumar" label="Lugares Cerrados Para Fumar" valuePropName="checked">
                <Checkbox />
              </FormItem>
            </Panel>

            <Panel header="Puestos" key="2" forceRender={true}
              extra={<Tooltip title="Agregar" placement="left"><PlusCircleTwoTone twoToneColor="#f15e21"
                onClick={event => {
                  event.stopPropagation();
                  setSelectedPuesto(undefined);
                  setShowPuestoModal(true);
                }}
              /></Tooltip>}
            >
              {puestos.length > 0
                ? <div style={{ overflow: 'auto', width: '100%' }}>
                  <AnexoIPuestosTable formMode
                    Puestos={puestos}
                    onRemove={record => {
                      setPuestos([...puestos.filter(item => (item.IdPuesto !== record.IdPuesto))]);
                      let datos = AuthenticationHelper.getDatosRemove();
                      datos.Puestos.push(record.IdPuesto);
                      AuthenticationHelper.storeDatosRemove(datos);
                    }}
                    onEdit={record => {
                      setSelectedPuesto(record);
                      setShowPuestoModal(true);
                    }}
                  /></div>
                : ''}
            </Panel>

            <Panel header="Sectores" key="3"
              extra={<Tooltip title="Agregar" placement="left"><PlusCircleTwoTone twoToneColor="#f15e21"
                onClick={event => {
                  event.stopPropagation();
                  setSelectedSector(undefined);
                  setShowSectorModal(true);
                }}
              /></Tooltip>}
            >
              {sectores.length > 0
                ? <div style={{ overflow: 'auto', width: '100%' }}>
                  <AnexoISectoresTable formMode
                    Sectores={sectores}
                    onRemove={record => { 
                      setSectores([...sectores.filter(item => (item.IdSector !== record.IdSector))]); 
                      let datos = AuthenticationHelper.getDatosRemove();
                      datos.Sectores.push(record.IdSector);
                      AuthenticationHelper.storeDatosRemove(datos);
                      }}
                    onEdit={record => {
                      setSelectedSector(record);
                      setShowSectorModal(true);
                    }}
                  /></div>
                : ''}
            </Panel>

            <Panel header="Responsable" key="4"
              extra={<Tooltip title="Agregar" placement="left"><PlusCircleTwoTone twoToneColor="#f15e21"
                onClick={event => {
                  event.stopPropagation();
                  setSelectedResponsable(undefined);
                  setShowResponsableModal(true);
                }}
              /></Tooltip>}
            >
              {responsables.length > 0
                ? <div style={{ overflow: 'auto', width: '100%' }}>
                  <AnexoIResponsablesTable formMode
                    Responsables={responsables}
                    onRemove={record => { 
                      setResponsables([...responsables.filter(item => (item.IdResponsable !== record.IdResponsable))]);
                      let datos = AuthenticationHelper.getDatosRemove();
                      datos.Responsables.push(record.IdResponsable);
                      AuthenticationHelper.storeDatosRemove(datos);
                      }}
                    onEdit={record => {
                      setSelectedResponsable(record);
                      setShowResponsableModal(true);
                    }}
                  /></div>
                : ''}
            </Panel>

            <Panel header="Contratistas" key="5"
              extra={<Tooltip title="Agregar" placement="left"><PlusCircleTwoTone twoToneColor="#f15e21"
                onClick={event => {
                  event.stopPropagation();
                  setSelectedContratista(undefined);
                  setShowContratistaModal(true);
                }}
              /></Tooltip>}
            >
              {contratistas.length > 0
                ? <div style={{ overflow: 'auto', width: '100%' }}>
                  <AnexoIContratistasTable formMode
                    Contratistas={contratistas}
                    onRemove={record => { 
                      setContratistas([...contratistas.filter(item => (item.IdContratista !== record.IdContratista))]);
                      let datos = AuthenticationHelper.getDatosRemove();
                      datos.Contratistas.push(record.IdContratista);
                      AuthenticationHelper.storeDatosRemove(datos);
                      }}
                    onEdit={record => {
                      setSelectedContratista(record);
                      setShowContratistaModal(true);
                    }}
                  /></div>
                : ''}
            </Panel>
          </Collapse>

        </Form>

        {
          showPuestoModal ?
            <>
              <AnexoIPuestoModal
                visible={showPuestoModal}
                puesto={selectedPuesto}
                onOk={puesto => {
                  let arr = [...puestos];
                  let idx = arr.findIndex(x => x.Hash ? x.Hash === puesto.Hash : x.IdPuesto === puesto.IdPuesto);
                  if (idx !== -1) { arr[idx] = puesto; }
                  else { arr.push(puesto); }
                  setPuestos([...arr]);
                  setShowPuestoModal(false);
                }}
                onCancel={() => setShowPuestoModal(false)}
              />
            </> : null
        }

        {
          showSectorModal ?
            <>
              <AnexoISectorModal
                visible={showSectorModal}
                sector={selectedSector}
                onOk={sector => {
                  let arr = [...sectores];
                  let idx = arr.findIndex(x => x.Hash ? x.Hash === sector.Hash : x.IdSector === sector.IdSector);
                  if (idx !== -1) { arr[idx] = sector; }
                  else { arr.push(sector); }
                  setSectores([...arr]);
                  setShowSectorModal(false);
                }}
                onCancel={() => setShowSectorModal(false)}
              />
            </> : null
        }

        {
          showResponsableModal ?
            <>
              <AnexoIResponsableModal
                visible={showResponsableModal}
                responsable={selectedResponsable}
                onOk={responsable => {
                  let arr = [...responsables];
                  let idx = arr.findIndex(x => x.Hash ? x.Hash === responsable.Hash : x.IdResponsable === responsable.IdResponsable);
                  if (idx !== -1) { arr[idx] = responsable; }
                  else { arr.push(responsable); }
                  setResponsables([...arr]);
                  setShowResponsableModal(false);
                }}
                onCancel={() => setShowResponsableModal(false)}
              />
            </> : null
        }

        {
          showContratistaModal ?
            <>
              <AnexoIContratistaModal
                visible={showContratistaModal}
                contratista={selectedContratista}
                onOk={contratista => {
                  let arr = [...contratistas];
                  let idx = arr.findIndex(x => x.Hash ? x.Hash === contratista.Hash : x.IdContratista === contratista.IdContratista);
                  if (idx !== -1) { arr[idx] = contratista; }
                  else { arr.push(contratista); }
                  setContratistas([...arr]);
                  setShowContratistaModal(false);
                }}
                onCancel={() => setShowContratistaModal(false)}
              />
            </> : null
        }

        <CampoObligatorio />

      </Spin>
    </Modal>
  );
}

export default AnexoIEstablecimientoModal;