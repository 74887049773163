import React, { useEffect, useState } from "react";
import { Space, Table } from "antd";
import parser from "../../../../../shared/helpers/parser";
import DeleteButton from "../../../../../shared/components/deleteButton";
import EditButton from "../../../../../shared/components/editButton";
import ReferenciasApi from "../../../../referencias/api/ReferenciasApi";
import Utils from "../../../../../shared/helpers/utils";

const AnexoIResponsablesTable = props => {
  const [contrataciones, setContrataciones] = useState([]);
  const [representaciones, setRepresentaciones] = useState([]);
  const [tiposProfResp, setTiposProfResp] = useState([]);

  useEffect(() => {
    ReferenciasApi.GetTabla("SVCC.TCONTRATACION").then((response) => { setContrataciones(response); })
    ReferenciasApi.GetTabla("SVCC.TREPRESENTACION").then((response) => { setRepresentaciones(response); })
    ReferenciasApi.GetTabla("SVCC.TTIPOPROFESIONALRESPONSABLE").then((response) => { setTiposProfResp(response); })
  }, []);

  return (
    <Table
      size="small"
      columns={ [
        { title: 'Id', dataIndex: 'IdResponsable', key: 'IdResponsable' },
        { title: 'Cuil/Cuit', dataIndex: 'CuilCuit', key: 'CuilCuit' },
        { title: 'Contratacion', dataIndex: 'IdContratacion', key: 'IdContratacion',
          render: text => Utils.getIdDesc(text, contrataciones)
        },
        { title: 'Representacion', dataIndex: 'IdRepresentacion', key: 'IdRepresentacion',
        render: text => Utils.getIdDesc(text, representaciones)
        },
        { title: 'Tipo Profesional Responsable', dataIndex: 'IdTipoProfesionalResponsable', key: 'IdTipoProfesionalResponsable',
          render: text => Utils.getIdDesc(text, tiposProfResp)
        },
        { title: 'Matricula', dataIndex: 'Matricula', key: 'Matricula' },
        { title: 'Horas Mensuales Asignadas', dataIndex: 'CantHorasAsignadas', key: 'CantHorasAsignadas' },
        //{ title: 'Fecha Insert', dataIndex: 'FechaInsert', key: 'FechaInsert', render: text => <span>{ parser.date(text) }</span> },
        props.formMode ? { title: '', dataIndex: 'Actions', key: 'Actions',
          render: (text, record) => {
            return <Space size="small">
              <EditButton onClick={ () => props.onEdit(record) }/>
              <DeleteButton onConfirm={ () => props.onRemove(record) }/>
              {/* { !record.IdResponsable ? <DeleteButton onConfirm={ () => props.onRemove(record) }/> : '' } */}
            </Space>
          }
        } : {},
      ] } 
      dataSource={ props.Responsables }
      rowKey={ (record, index) => index }
      pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
    />
  );
}

export default AnexoIResponsablesTable;