import React from "react";
import { Button, Tooltip } from "antd";
import { EditTwoTone } from '@ant-design/icons';

const EditButton = props => {
  return (
    <Tooltip 
      title={ props.btnTitle ? props.btnTitle : 'Editar' } 
      placement="left">
      <Button 
        shape="circle" 
        size="small" 
        //type={ props.btnType ? props.btnType : 'primary' } 
        icon={ props.icon ? props.icon : <EditTwoTone twoToneColor="#f15e21" /> }
        onClick={ props.onClick }
      />
    </Tooltip>
  );
}

export default EditButton;