import React, { useEffect, useState } from "react";
import { Form, Input, Spin, Select, Collapse, Modal, Tooltip, Checkbox, InputNumber, notification } from "antd";
import { PlusCircleTwoTone } from '@ant-design/icons';
import { FormConfig } from "../../../../shared/config/GlobalConfig";
import EstablecimientosApi from "../../../establecimientos/api/EstablecimientosApi";
import Utils from "../../../../shared/helpers/utils";
import ReferenciasApi from "../../../referencias/api/ReferenciasApi";
import AnexoVUtilizacionesTable from "../view/sustancias/AnexoVUtilizacionesTable";
import AnexoVUtilizacionModal from "./sustancias/AnexoVUtilizacionModal";
import AnexoVPuestosAfectadosTable from "../view/sustancias/AnexoVPuestosAfectadosTable";
import AnexoVPuestoAfectadoModal from "./sustancias/AnexoVPuestoAfectadoModal";
import AnexoVEquiposRadiologicosTable from "../view/sustancias/AnexoVEquiposRadiologicosTable";
import AnexoVEquipoRadiologicoModal from "./sustancias/AnexoVEquipoRadiologicoModal";
import AnexoVProveedoresTable from "../view/sustancias/AnexoVProveedoresTable";
import AnexoVProveedorModal from "./sustancias/AnexoVProveedorModal";
import AnexoVCompradoresTable from "../view/sustancias/AnexoVCompradoresTable";
import AnexoVCompradorModal from "./sustancias/AnexoVCompradorModal";
import AnexoVEstudiosAmbientalesEspecificosTable from "../view/sustancias/AnexoVEstudiosAmbientalesEspecificosTable";
import AnexoVEstudioAmbientalEspecificoModal from "./sustancias/AnexoVEstudioAmbientalEspecificoModal";
import AnexoVEstudiosBiologicosEspecificosTable from "../view/sustancias/AnexoVEstudiosBiologicosEspecificosTable";
import AnexoVEstudioBiologicoEspecificoModal from "./sustancias/AnexoVEstudioBiologicoEspecificoModal";
import EstablecimientoEmpresaSelectFormItem from "../../../../shared/components/establecimientoEmpresaSelectFormItem";
import ReferenciaSelectFormItem from "../../../../shared/components/referenciaSelectFormItem";
import CampoObligatorio from "../../../../shared/components/campoObligatorio";
import AuthenticationHelper from "../../../../shared/helpers/authenticationHelper";

const FormItem = Form.Item;
const { Option } = Select;
const { Panel } = Collapse;

const AnexoVSustanciaModal = props => {
  const [establecimientosEmpresa, setEstablecimientosEmpresa] = useState([]);
  const [sustancias, setSustancias] = useState([]);
  const [unidadesMedida, setUnidadesMedida] = useState([]);
  const [utilizaciones, setUtilizaciones] = useState([]);
  const [puestosAfectados, setPuestosAfectados] = useState([]);
  const [equiposRadiologicos, setEquiposRadiologicos] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [compradores, setCompradores] = useState([]);
  const [estudiosAmbientalesEspecificos, setEstudiosAmbientalesEspecificos] = useState([]);
  const [estudiosBiologicosEspecificos, setEstudiosBiologicosEspecificos] = useState([]);
  const [showUtilizacionModal, setShowUtilizacionModal] = useState(false);
  const [showPuestoAfectadoModal, setShowPuestoAfectadoModal] = useState(false);
  const [showEquipoRadiologicoModal, setShowEquipoRadiologicoModal] = useState(false);
  const [showProveedorModal, setShowProveedorModal] = useState(false);
  const [showCompradorModal, setShowCompradorModal] = useState(false);
  const [showEstudioAmbientalEspecificoModal, setShowEstudioAmbientalEspecificoModal] = useState(false);
  const [showEstudioBiologicoEspecificoModal, setShowEstudioBiologicoEspecificoModal] = useState(false);
  const [selectedUtilizacion, setSelectedUtilizacion] = useState(undefined);
  const [selectedPuestoAfectado, setSelectedPuestoAfectado] = useState(undefined);
  const [selectedEquipoRadiologico, setSelectedEquipoRadiologico] = useState(undefined);
  const [selectedProveedor, setSelectedProveedor] = useState(undefined);
  const [selectedComprador, setSelectedComprador] = useState(undefined);
  const [selectedEstudioAmbientalEspecifico, setSelectedEstudioAmbientalEspecifico] = useState(undefined);
  const [selectedEstudioBiologicoEspecifico, setSelectedEstudioBiologicoEspecifico] = useState(undefined);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    EstablecimientosApi.GetEmpresasByCuit(props.cuit).then((response) => { setEstablecimientosEmpresa(response); })
    ReferenciasApi.GetTabla("SVCC.TSUSTANCIAS").then(response => { setSustancias(response); });
    ReferenciasApi.GetTabla("SVCC.TUNIDADES_MEDIDAS").then(response => { setUnidadesMedida(response); });
  }, []);

  useEffect(() => {
    if (props.visible === true) {
      form.resetFields();
      setUtilizaciones(props.sustancia ? props.sustancia.Utilizaciones : []);
      setPuestosAfectados(props.sustancia ? props.sustancia.PuestosAfectados : []);
      setEquiposRadiologicos(props.sustancia ? props.sustancia.EquiposRadiologicos : []);
      setProveedores(props.sustancia ? props.sustancia.Proveedores : []);
      setCompradores(props.sustancia ? props.sustancia.Compradores : []);
      setEstudiosAmbientalesEspecificos(props.sustancia ? props.sustancia.EstudiosAmbientalesEspecificos : []);
      setEstudiosBiologicosEspecificos(props.sustancia ? props.sustancia.EstudiosBiologicosEspecificos : []);
    }
  }, [props.visible]);

  const handleOk = () => {
    form.validateFields()
      .then(values => {
        props.onOk({
          Hash: props.sustancia ? props.sustancia.Hash : Utils.generateHash(),
          ...values,
          Utilizaciones: utilizaciones,
          PuestosAfectados: puestosAfectados,
          EquiposRadiologicos: equiposRadiologicos,
          Proveedores: proveedores,
          Compradores: compradores,
          EstudiosAmbientalesEspecificos: estudiosAmbientalesEspecificos,
          EstudiosBiologicosEspecificos: estudiosBiologicosEspecificos,
        });
      })
      .catch(error => {
        notification.warning({ message: 'Error de validación', description: 'Revise los campos del formulario', placement: 'bottomRight' });
      });
  }

  const getEstablecimiento = () => {
    let id = form.getFieldValue('IdEstablecimientoEmpresa') ? form.getFieldValue('IdEstablecimientoEmpresa') : props.sustancia?.IdEstablecimientoEmpresa;
    return props.anexoi?.EstablecimientosDeclarados[props.anexoi?.EstablecimientosDeclarados.findIndex(x => x.IdEstablecimientoEmpresa == id)]
  }

  const filterEstablecimientos = () => {
    return establecimientosEmpresa.filter(x => props.anexoi?.EstablecimientosDeclarados.find(dec => dec.IdEstablecimientoEmpresa == x.Codigo));
  }

  return (
    <Modal
      title={`${props.sustancia ? 'Editar' : 'Agregar'} Sustancia`}
      width="80%"
      visible={props.visible}
      onOk={handleOk}
      onCancel={props.onCancel}
    >
      <Spin spinning={working}>
        <Form
          {...FormConfig.DefaultLayout}
          form={form}
          name="formulario"
          initialValues={{ ...props.sustancia }}
          scrollToFirstError
        >
          <Collapse defaultActiveKey="1">
            <Panel header="Sustancia" key="1">
              {props.sustancia?.IdSustanciaDeclarada ? <FormItem name="IdSustanciaDeclarada" label="Sustancia Declarada" rules={FormConfig.DefaultRules}>
                <Input disabled />
              </FormItem> : ''}

              {props.sustancia
                ? <FormItem name="IdEstablecimientoEmpresa" label="Establecimiento Empresa" rules={FormConfig.DefaultRules}>
                  <Input disabled />
                </FormItem>
                : <EstablecimientoEmpresaSelectFormItem name="IdEstablecimientoEmpresa" label="Establecimiento Empresa"
                  rules={FormConfig.DefaultRules}
                  establecimientosEmpresa={filterEstablecimientos()}
                />}

              <ReferenciaSelectFormItem name="IdSustancia" label="Sustancia"
                rules={FormConfig.DefaultRules}
                tabla={sustancias}
              />

              <FormItem name="NombreComercial" label="Nombre Comercial" /*rules={ FormConfig.DefaultRules }*/>
                <Input />
              </FormItem>

              <FormItem name="CantidadAnual" label="Cantidad Anual" /*rules={ FormConfig.DefaultRules }*/>
                <InputNumber min={0} style={{ width: '100%' }} />
              </FormItem>

              <ReferenciaSelectFormItem name="IdUnidadDeMedida" label="Unidad de Medida"
                //rules={ FormConfig.DefaultRules } 
                tabla={unidadesMedida}
              />
            </Panel>

            <Panel header="Utilizaciones" key="2" forceRender={true}
              extra={<Tooltip title="Agregar" placement="left"><PlusCircleTwoTone twoToneColor="#f15e21"
                onClick={event => {
                  setSelectedUtilizacion(undefined);
                  setShowUtilizacionModal(true);
                  event.stopPropagation();
                }}
              /></Tooltip>}
            >
              {utilizaciones.length > 0
                ? <div style={{ overflow: 'auto', width: '100%' }}>
                  <AnexoVUtilizacionesTable formMode
                    Utilizaciones={utilizaciones}
                    onRemove={record => {
                      setUtilizaciones([...utilizaciones.filter(item => (item.IdUtilizacion !== record.IdUtilizacion))]);
                      let datos = AuthenticationHelper.getDatosRemove();
                      datos.Utilizaciones.push(record.IdUtilizacion);
                      AuthenticationHelper.storeDatosRemove(datos);
                    }}
                    onEdit={record => {
                      setSelectedUtilizacion(record);
                      setShowUtilizacionModal(true);
                    }}
                  /></div>
                : ''}
            </Panel>

            <Panel header="Puestos Afectados" key="3"
              extra={<Tooltip title="Agregar" placement="left"><PlusCircleTwoTone twoToneColor="#f15e21"
                onClick={event => {
                  event.stopPropagation();
                  setSelectedPuestoAfectado(undefined);
                  setShowPuestoAfectadoModal(true);
                }}
              /></Tooltip>}
            >
              {puestosAfectados.length > 0
                ? <div style={{ overflow: 'auto', width: '100%' }}>
                  <AnexoVPuestosAfectadosTable formMode
                    PuestosAfectados={puestosAfectados}
                    puestos={getEstablecimiento()?.Puestos}
                    onRemove={record => { 
                      setPuestosAfectados([...puestosAfectados.filter(item => (item.IdPuestoAfectado !== record.IdPuestoAfectado))]); 
                      let datos = AuthenticationHelper.getDatosRemove();
                      datos.PuestosAfectados.push(record.IdPuestoAfectado);
                      AuthenticationHelper.storeDatosRemove(datos);
                      }}
                    onEdit={record => {
                      setSelectedPuestoAfectado(record);
                      setShowPuestoAfectadoModal(true);
                    }}
                  /></div>
                : ''}
            </Panel>

            <Panel header="Equipos Radiologicos" key="4"
              extra={<Tooltip title="Agregar" placement="left"><PlusCircleTwoTone twoToneColor="#f15e21"
                onClick={event => {
                  event.stopPropagation();
                  setSelectedEquipoRadiologico(undefined);
                  setShowEquipoRadiologicoModal(true);
                }}
              /></Tooltip>}
            >
              {equiposRadiologicos.length > 0
                ? <div style={{ overflow: 'auto', width: '100%' }}>
                  <AnexoVEquiposRadiologicosTable formMode
                    EquiposRadiologicos={equiposRadiologicos}
                    onRemove={record => { 
                      setEquiposRadiologicos([...equiposRadiologicos.filter(item => (item.IdEquipoRadiologico !== record.IdEquipoRadiologico))]); 
                      let datos = AuthenticationHelper.getDatosRemove();
                      datos.EquiposRadiologicos.push(record.IdEquipoRadiologico);
                      AuthenticationHelper.storeDatosRemove(datos);
                      }}
                    onEdit={record => {
                      setSelectedEquipoRadiologico(record);
                      setShowEquipoRadiologicoModal(true);
                    }}
                  /></div>
                : ''}
            </Panel>

            <Panel header="Proveedores" key="5"
              extra={<Tooltip title="Agregar" placement="left"><PlusCircleTwoTone twoToneColor="#f15e21"
                onClick={event => {
                  event.stopPropagation();
                  setSelectedProveedor(undefined);
                  setShowProveedorModal(true);
                }}
              /></Tooltip>}
            >
              {proveedores.length > 0
                ? <div style={{ overflow: 'auto', width: '100%' }}>
                  <AnexoVProveedoresTable formMode
                    Proveedores={proveedores}
                    onRemove={record => { 
                      setProveedores([...proveedores.filter(item => (item.IdProveedor !== record.IdProveedor))]); 
                      let datos = AuthenticationHelper.getDatosRemove();
                      datos.Proveedores.push(record.IdProveedor);
                      AuthenticationHelper.storeDatosRemove(datos);
                      }}
                    onEdit={record => {
                      setSelectedProveedor(record);
                      setShowProveedorModal(true);
                    }}
                  /></div>
                : ''}
            </Panel>

            <Panel header="Compradores" key="6"
              extra={<Tooltip title="Agregar" placement="left"><PlusCircleTwoTone twoToneColor="#f15e21"
                onClick={event => {
                  event.stopPropagation();
                  setSelectedComprador(undefined);
                  setShowCompradorModal(true);
                }}
              /></Tooltip>}
            >
              {compradores.length > 0
                ? <div style={{ overflow: 'auto', width: '100%' }}>
                  <AnexoVCompradoresTable formMode
                    Compradores={compradores}
                    onRemove={record => { 
                      setCompradores([...compradores.filter(item => (item.IdComprador !== record.IdComprador))]); 
                      let datos = AuthenticationHelper.getDatosRemove();
                      datos.Compradores.push(record.IdComprador);
                      AuthenticationHelper.storeDatosRemove(datos);
                      }}
                    onEdit={record => {
                      setSelectedComprador(record);
                      setShowCompradorModal(true);
                    }}
                  /></div>
                : ''}
            </Panel>

            <Panel header="Estudios Ambientales Especificos" key="7"
              extra={<Tooltip title="Agregar" placement="left"><PlusCircleTwoTone twoToneColor="#f15e21"
                onClick={event => {
                  event.stopPropagation();
                  setSelectedEstudioAmbientalEspecifico(undefined);
                  setShowEstudioAmbientalEspecificoModal(true);
                }}
              /></Tooltip>}
            >
              {estudiosAmbientalesEspecificos.length > 0
                ? <div style={{ overflow: 'auto', width: '100%' }}>
                  <AnexoVEstudiosAmbientalesEspecificosTable formMode
                    EstudiosAmbientalesEspecificos={estudiosAmbientalesEspecificos}
                    onRemove={record => { 
                      setEstudiosAmbientalesEspecificos([...estudiosAmbientalesEspecificos.filter(item => (item.IdEstudioAmbientaleEspecifico !== record.IdEstudioAmbientaleEspecifico))]); 
                      let datos = AuthenticationHelper.getDatosRemove();
                      datos.EstudiosAmbientalesEspecificos.push(record.IdEstudioAmbientalEspecifico);
                      AuthenticationHelper.storeDatosRemove(datos);
                      }}
                    onEdit={record => {
                      setSelectedEstudioAmbientalEspecifico(record);
                      setShowEstudioAmbientalEspecificoModal(true);
                    }}
                  /></div>
                : ''}
            </Panel>

            <Panel header="Estudios Biologicos Especificos" key="8"
              extra={<Tooltip title="Agregar" placement="left"><PlusCircleTwoTone twoToneColor="#f15e21"
                onClick={event => {
                  event.stopPropagation();
                  setSelectedEstudioBiologicoEspecifico(undefined);
                  setShowEstudioBiologicoEspecificoModal(true);
                }}
              /></Tooltip>}
            >
              {estudiosBiologicosEspecificos.length > 0
                ? <div style={{ overflow: 'auto', width: '100%' }}>
                  <AnexoVEstudiosBiologicosEspecificosTable formMode
                    EstudiosBiologicosEspecificos={estudiosBiologicosEspecificos}
                    onRemove={record => { 
                      setEstudiosBiologicosEspecificos([...estudiosBiologicosEspecificos.filter(item => (item.IdEstudioBiologicoEspecifico !== record.IdEstudioBiologicoEspecifico))]); 
                      let datos = AuthenticationHelper.getDatosRemove();
                      datos.EstudiosBiologicosEspecificos.push(record.IdEstudioBiologicoEspecifico);
                      AuthenticationHelper.storeDatosRemove(datos);
                      }}
                    onEdit={record => {
                      setSelectedEstudioBiologicoEspecifico(record);
                      setShowEstudioBiologicoEspecificoModal(true);
                    }}
                  /></div>
                : ''}
            </Panel>
          </Collapse>

        </Form>
        {
          showUtilizacionModal ?
            <>
              <AnexoVUtilizacionModal
                visible={showUtilizacionModal}
                utilizacion={selectedUtilizacion}
                sustancia={props.sustancia}
                onOk={utilizacion => {
                  let arr = [...utilizaciones];
                  let idx = arr.findIndex(x => x.Hash ? x.Hash === utilizacion.Hash : x.IdUtilizacion === utilizacion.IdUtilizacion);
                  if (idx !== -1) { arr[idx] = utilizacion; }
                  else { arr.push(utilizacion); }
                  setUtilizaciones([...arr]);
                  setShowUtilizacionModal(false);
                }}
                onCancel={() => setShowUtilizacionModal(false)}
              />
            </> : null
        }

        {
          showPuestoAfectadoModal ?
            <>
              <AnexoVPuestoAfectadoModal
                visible={showPuestoAfectadoModal}
                puestoAfectado={selectedPuestoAfectado}
                sustancia={props.sustancia}
                puestos={getEstablecimiento()?.Puestos}
                onOk={puesto => {
                  let arr = [...puestosAfectados];
                  let idx = arr.findIndex(x => x.Hash ? x.Hash === puesto.Hash : x.IdPuestoAfectado === puesto.IdPuestoAfectado);
                  if (idx !== -1) { arr[idx] = puesto; }
                  else { arr.push(puesto); }
                  setPuestosAfectados([...arr]);
                  setShowPuestoAfectadoModal(false);
                }}
                onCancel={() => setShowPuestoAfectadoModal(false)}
              />
            </> : null
        }

        {
          showEquipoRadiologicoModal ?
            <>
              <AnexoVEquipoRadiologicoModal
                visible={showEquipoRadiologicoModal}
                equipo={selectedEquipoRadiologico}
                sustancia={props.sustancia}
                onOk={equipo => {
                  let arr = [...equiposRadiologicos];
                  let idx = arr.findIndex(x => x.Hash ? x.Hash === equipo.Hash : x.IdEquipoRadiologico === equipo.IdEquipoRadiologico);
                  if (idx !== -1) { arr[idx] = equipo; }
                  else { arr.push(equipo); }
                  setEquiposRadiologicos([...arr]);
                  setShowEquipoRadiologicoModal(false);
                }}
                onCancel={() => setShowEquipoRadiologicoModal(false)}
              />
            </> : null
        }

        {
          showProveedorModal ?
            <>
              <AnexoVProveedorModal
                visible={showProveedorModal}
                proveedor={selectedProveedor}
                sustancia={props.sustancia}
                onOk={proveedor => {
                  let arr = [...proveedores];
                  let idx = arr.findIndex(x => x.Hash ? x.Hash === proveedor.Hash : x.IdProveedor === proveedor.IdProveedor);
                  if (idx !== -1) { arr[idx] = proveedor; }
                  else { arr.push(proveedor); }
                  setProveedores([...arr]);
                  setShowProveedorModal(false);
                }}
                onCancel={() => setShowProveedorModal(false)}
              />
            </> : null
        }

        {
          showCompradorModal ?
            <>
              <AnexoVCompradorModal
                visible={showCompradorModal}
                comprador={selectedComprador}
                sustancia={props.sustancia}
                onOk={comprador => {
                  let arr = [...compradores];
                  let idx = arr.findIndex(x => x.Hash ? x.Hash === comprador.Hash : x.IdComprador === comprador.IdComprador);
                  if (idx !== -1) { arr[idx] = comprador; }
                  else { arr.push(comprador); }
                  setCompradores([...arr]);
                  setShowCompradorModal(false);
                }}
                onCancel={() => setShowCompradorModal(false)}
              />
            </> : null
        }

        {
          showEstudioAmbientalEspecificoModal ?
            <>
              <AnexoVEstudioAmbientalEspecificoModal
                visible={showEstudioAmbientalEspecificoModal}
                estudio={selectedEstudioAmbientalEspecifico}
                sustancia={props.sustancia}
                onOk={estudio => {
                  let arr = [...estudiosAmbientalesEspecificos];
                  let idx = arr.findIndex(x => x.Hash ? x.Hash === estudio.Hash : x.IdEstudioAmbientalEspecifico === estudio.IdEstudioAmbientalEspecifico);
                  if (idx !== -1) { arr[idx] = estudio; }
                  else { arr.push(estudio); }
                  setEstudiosAmbientalesEspecificos([...arr]);
                  setShowEstudioAmbientalEspecificoModal(false);
                }}
                onCancel={() => setShowEstudioAmbientalEspecificoModal(false)}
              />
            </> : null
        }

        {
          showEstudioBiologicoEspecificoModal ?
            <>
              <AnexoVEstudioBiologicoEspecificoModal
                visible={showEstudioBiologicoEspecificoModal}
                estudio={selectedEstudioBiologicoEspecifico}
                sustancia={props.sustancia}
                onOk={estudio => {
                  let arr = [...estudiosBiologicosEspecificos];
                  let idx = arr.findIndex(x => x.Hash ? x.Hash === estudio.Hash : x.IdEstudioBiologicoEspecifico === estudio.IdEstudioBiologicoEspecifico);
                  if (idx !== -1) { arr[idx] = estudio; }
                  else { arr.push(estudio); }
                  setEstudiosBiologicosEspecificos([...arr]);
                  setShowEstudioBiologicoEspecificoModal(false);
                }}
                onCancel={() => setShowEstudioBiologicoEspecificoModal(false)}
              />
            </> : null
        }

        <CampoObligatorio />

      </Spin>
    </Modal>
  );
}

export default AnexoVSustanciaModal;