import React, { useEffect, useState } from "react";
import { Space, Table } from "antd";
import DeleteButton from "../../../../../../shared/components/deleteButton";
import EditButton from "../../../../../../shared/components/editButton";
import ReferenciasApi from "../../../../../referencias/api/ReferenciasApi";
import Utils from "../../../../../../shared/helpers/utils";

const NominaExamenesMedicosTable = props => {
  const [examenesMedicos, setExamenesMedicos] = useState([]);

  useEffect(() => {
    ReferenciasApi.GetTabla("SVCC.EXAMENMEDICO").then(response => { setExamenesMedicos(response); });
  }, []);

  return (
    <Table
      size="small"
      columns={ [
        { title: 'Id', dataIndex: 'IdExamenDeclarado', key: 'IdExamenDeclarado' },
        { title: 'Id Examen', dataIndex: 'IdExamen', key: 'IdExamen',
          render: text => Utils.getIdDesc(text, examenesMedicos)
        },
        props.formMode ? { title: '', dataIndex: 'Actions', key: 'Actions',
          render: (text, record) => {
            return <Space size="small">
              <EditButton onClick={ () => props.onEdit(record) }/>
              { !record.IdExamenDeclarado ? <DeleteButton onConfirm={ () => props.onRemove(record) }/> : '' }
            </Space>
          }
        } : {},
      ] } 
      dataSource={ props.ExamenesMedicos }
      rowKey={ (record, index) => index }
      pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
    />
  );
}

export default NominaExamenesMedicosTable;