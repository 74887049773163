import Request from "../../../shared/helpers/request";
import AuthenticationHelper from "../../../shared/helpers/authenticationHelper";
//import AuthorizationHelper from "../../../shared/helpers/authorizationHelper";

const AuthApi = {
  ForceTicket: ws => {
    return Request
      .get("auth/ticket/force?ws=" + ws)
      .then((response) => {
        AuthenticationHelper.storeSRTTicket(
          response.data.Value
        );
        AuthenticationHelper.storeSRTWS(ws);
      })
      .catch((error) => {
        console.error("fallo");
        throw error;
      });
  },
  GetTicket: ws => {
    return Request
      .get("auth/ticket?ws=" + ws)
      .then((response) => {
        AuthenticationHelper.storeSRTTicket(
          response.data.Value
        );
        AuthenticationHelper.storeSRTWS(ws);
      })
      .catch((error) => {
        console.error("fallo");
        throw error;
      });
  },

  Encrypt: password => {
    return Request
      .get("auth/encrypt2?password=" + password)
      .then((response) => {
          return response.data;
      })
      .catch((error) => {
        console.error("fallo");
        throw error;
      });
  },

  Decrypt: password => {
    return Request
      .get("auth/decrypt2?password=" + password)
      .then((response) => {
          return response.data;
      })
      .catch((error) => {
        console.error("fallo");
        throw error;
      });
  },
}

export default AuthApi;