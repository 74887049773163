import React, { Fragment, useEffect, useState } from "react";
import { Spin, Modal, Button, notification, Row, Col, Alert } from "antd";
import { CloudUploadOutlined } from '@ant-design/icons';
import Dragger from "antd/lib/upload/Dragger";
import NominasApi from "../../api/NominasApi";
import NominaFilesTable from "./NominaFilesTable";
import AuthenticationHelper from "../../../../shared/helpers/authenticationHelper";
import PresentacionesApi from "../../../presentaciones/api/PresentacionesApi";

const NominaUplodaModal = props => {
  const [files, setFiles] = useState([]);
  const [working, setWorking] = useState(false);
  let idsEstablecimientosUsuario = JSON.parse(AuthenticationHelper.getIdsEstablecimientosUsuario());
  let tipoUsuario = AuthenticationHelper.getTipoUsuario();

  useEffect(() => {

  }, []);

  useEffect(() => {
    if (props.visible === true) {
      setWorking(false);
      setFiles([]);
    }
  }, [props.visible]);

  const handleUpload = () => {
    var trabajadores = new Object();
    trabajadores.Trabajadores = [];

    setWorking(true);
    NominasApi.ImportCSV(files[0])
      .then(response => {
        setWorking(false);
        try {
          if (idsEstablecimientosUsuario !== null) {
            idsEstablecimientosUsuario.forEach(idEstablecimiento => {
              response.Trabajadores.forEach(trabajadorResponse => {
                if (trabajadorResponse.IdEstablecimientoEmpresa == idEstablecimiento.toString()) {
                  trabajadores.Trabajadores.push(trabajadorResponse);
                }
              });
            });
            if (trabajadores.Trabajadores.length > 0) {
              props.importCSV(trabajadores);
              notification.success({ message: 'Exito', description: 'Archivo importado correctamente', placement: 'bottomRight' });
            } else {
              notification.error({ message: 'Error', description: '¡Los trabajadores no pertenecen a tus establecimientos asociados!', placement: 'bottomRight' });
            }
          } else if (tipoUsuario == "Administrador Presentacion") {
            setWorking(true);
            var presentacionUsuairo = JSON.parse(AuthenticationHelper.getPresentacionUsuario());
            PresentacionesApi.Get(presentacionUsuairo[0].Id, presentacionUsuairo[0].Cuit)
              .then(response1 => {
                var EstablecimientosPresentacion = response1.AnexoI.EstablecimientosDeclarados;
                EstablecimientosPresentacion.forEach(Establecimiento => {
                  response.Trabajadores.forEach(trabajadorResponse => {
                    if (trabajadorResponse.IdEstablecimientoEmpresa == Establecimiento.IdEstablecimientoEmpresa.toString()) {
                      trabajadores.Trabajadores.push(trabajadorResponse);
                    }
                  });
                });
                if (trabajadores.Trabajadores.length > 0) {
                  props.importCSV(trabajadores);
                  notification.success({ message: 'Exito', description: 'Archivo importado correctamente', placement: 'bottomRight' });
                } else {
                  notification.error({ message: 'Error', description: '¡Los trabajadores no pertenecen a tus establecimientos asociados!', placement: 'bottomRight' });
                }
                setWorking(false);
              })
              .catch(error => {
                setWorking(false);
              });
          }
          else {
            props.importCSV(response);
            notification.success({ message: 'Exito', description: 'Archivo importado correctamente', placement: 'bottomRight' });
          }
        }
        catch (ExceptionMessage) {
          setWorking(false);
        }

      })
      .catch(error => {
        setWorking(false);
      });
  }

  const onRemove = file => {
    let arr = [...files];
    let newArr = [...arr.filter(item => (item.name !== file.name))];
    setFiles(newArr);
  }

  const beforeUpload = file => {
    setFiles([file]);
    return false;
  }

  return (
    <Modal
      title={'Importar Nómina'}
      width="60%"
      visible={props.visible}
      onOk={handleUpload}
      onCancel={props.onCancel}
      footer={[
        <Button onClick={props.onCancel}>Cancelar</Button>,
        <Button type="primary" onClick={handleUpload} disabled={files.length === 0}>Aceptar</Button>,
      ]}
    >
      <Spin spinning={working}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Alert
              type="info"
              description={
                <Fragment>
                  <p><b>¡Los trabajadores importados deben coresponder a su/sus establecimientos asociados!</b></p>
                  <span>Recuerde que el archivo debe respetar la siguiente estructura:</span>
                  <ul>
                    <li>T;[IdEstablecimientoEmpresa];[Cuil];[FechaIngreso]</li>
                    <li>A;[IdEstablecimientoEmpresa];[IdPuestoDeclarado];[IdSectorDeclarado];[Permanente];[IdSustanciaDeclarada];[FechaInicioExposicion];[FechaFinExposicion]</li>
                    <li>E;[IdExamen]</li>
                    <span><i>- Formato de Fecha: DD/MM/AAAA. Ejemplo: 02/12/2022.</i></span>
                  </ul>

                  <p><b>T</b> = Trabajador - <b>A</b> = Actividad - <b>E</b> = Examen Medico</p>
                  <span><i>Nota: los campos [IdSustanciaDeclarada], [FechaInicioExposicion] y [FechaFinExposicion] son opcionales.</i></span>

                </Fragment>
              }
              showIcon
            />
          </Col>
          <Col span={24}>
            <Dragger
              name="files"
              multiple={false}
              onRemove={onRemove}
              beforeUpload={beforeUpload}
              fileList={files}
              accept=".csv"
              showUploadList={false}
            >
              <p className="ant-upload-drag-icon">
                <CloudUploadOutlined />
              </p>
              <p className="ant-upload-text">Haga click o arrastre un archivo para importar</p>
              <p className="ant-upload-hint">Solo se aceptan archivos .csv</p>
            </Dragger>
          </Col>
          <Col span={24}>
            {files.length > 0
              ? <NominaFilesTable
                files={files}
                showActions={working === false}
                onDelete={onRemove}
              />
              : ''}
          </Col>
        </Row>

      </Spin>
    </Modal>
  );
}

export default NominaUplodaModal;