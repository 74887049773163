import React, { useEffect, useState } from "react";
import { Card, Space, Table } from "antd";
import parser from "../../../../shared/helpers/parser";
import AnexoVUtilizacionesTable from "./sustancias/AnexoVUtilizacionesTable";
import AnexoVPuestosAfectadosTable from "./sustancias/AnexoVPuestosAfectadosTable";
import AnexoVEquiposRadiologicosTable from "./sustancias/AnexoVEquiposRadiologicosTable";
import AnexoVProveedoresTable from "./sustancias/AnexoVProveedoresTable";
import AnexoVCompradoresTable from "./sustancias/AnexoVCompradoresTable";
import AnexoVEstudiosAmbientalesEspecificosTable from "./sustancias/AnexoVEstudiosAmbientalesEspecificosTable";
import AnexoVEstudiosBiologicosEspecificosTable from "./sustancias/AnexoVEstudiosBiologicosEspecificosTable";
import DeleteButton from "../../../../shared/components/deleteButton";
import EditButton from "../../../../shared/components/editButton";
import EstablecimientosApi from "../../../establecimientos/api/EstablecimientosApi";
import ReferenciasApi from "../../../referencias/api/ReferenciasApi";
import Utils from "../../../../shared/helpers/utils";
import AuthenticationHelper from "../../../../shared/helpers/authenticationHelper";

const AnexoVSustanciasTable = props => {
  const [sustancias, setSustancias] = useState([]);
  const [establecimientosEmpresa, setEstablecimientosEmpresa] = useState([]);
  const [unidadesMedida, setUnidadesMedida] = useState([]);
  const permisosUsuario = AuthenticationHelper.getTipoUsuario() !== 'Operador';
  let sustanciasProps = props.Sustancias;
  var encargadosPrevios = [];

  useEffect(() => {
    EstablecimientosApi.GetEmpresasByCuit(props.cuit).then((response) => { setEstablecimientosEmpresa(response); })
    ReferenciasApi.GetTabla("SVCC.TSUSTANCIAS").then(response => { setSustancias(response); });
    ReferenciasApi.GetTabla("SVCC.TUNIDADES_MEDIDAS").then(response => { setUnidadesMedida(response); });

    sustanciasProps.forEach(sustancia => {
      sustancia.EquiposRadiologicos.forEach(equipo => {
        encargadosPrevios = [... new Set(equipo.EncargadosDeProteccionRadiologica)];

        var idEncargado = -1;
        var encargadosFinales = encargadosPrevios.map(encargado => {
          if (encargado.IdEncargadoDeProteccionRadiologica !== idEncargado) {
            idEncargado = encargado.IdEncargadoDeProteccionRadiologica;
            return encargado;
          } else { return null }
        }).filter(encargado => encargado !== null);

        equipo.EncargadosDeProteccionRadiologica.splice(0);
        encargadosFinales.forEach(encargadoFinal => {
          equipo.EncargadosDeProteccionRadiologica.push(encargadoFinal);
        });
      });
    });

  }, []);

  const getEstablecimiento = id => {
    return props.anexoi.EstablecimientosDeclarados[props.anexoi.EstablecimientosDeclarados.findIndex(x => x.IdEstablecimientoEmpresa == id)]
  }

  return (
    <Table
      style={{ overflow: 'auto' }}
      size="small"
      columns={[
        { title: 'Sustancia Declarada', dataIndex: 'IdSustanciaDeclarada', key: 'IdSustanciaDeclarada' },
        {
          title: 'Sustancia', dataIndex: 'IdSustancia', key: 'IdSustancia',
          render: text => Utils.getIdDesc(text, sustancias)
        },
        {
          title: 'Establecimiento Empresa', dataIndex: 'IdEstablecimientoEmpresa', key: 'IdEstablecimientoEmpresa',
          render: text => Utils.getIdDesc(text, establecimientosEmpresa)
        },
        { title: 'Nombre Comercial', dataIndex: 'NombreComercial', key: 'NombreComercial' },
        { title: 'Cantidad Anual', dataIndex: 'CantidadAnual', key: 'CantidadAnual' },
        {
          title: 'Unidad de Medida', dataIndex: 'IdUnidadDeMedida', key: 'IdUnidadDeMedida',
          render: text => Utils.getIdDesc(text, unidadesMedida)
        },
        //{ title: 'Fecha Insert', dataIndex: 'FechaInsert', key: 'FechaInsert', render: text => <span>{ parser.date(text) }</span> },
        props.formMode ? {
          title: '', dataIndex: 'Actions', key: 'Actions',
          render: (text, record) => {
            return <Space size="small">
              {
                permisosUsuario ?
                  <>
                    <EditButton onClick={() => props.onEdit(record)} />
                    <DeleteButton onConfirm={() => props.onRemove(record)} />
                    {/* {!record.IdSustanciaDeclarada ? <DeleteButton onConfirm={() => props.onRemove(record)} /> : ''} */}
                  </> : null
              }
            </Space>
          }
        } : {},
      ]}
      dataSource={props.Sustancias}
      rowKey={(record, index) => index}
      pagination={{ hideOnSinglePage: true, pageSize: 10, showSizeChanger: false }}
      expandable={{
        expandedRowRender: record => <Card>
          <Card title="Utilizaciones" type="inner" style={{ marginBottom: 16 }}>
            {record.Utilizaciones?.length > 0
              ? <AnexoVUtilizacionesTable Utilizaciones={record.Utilizaciones} />
              : ''}
          </Card>
          <Card title="Puestos Afectados" type="inner" style={{ marginBottom: 16 }}>
            {record.PuestosAfectados?.length > 0
              ? <AnexoVPuestosAfectadosTable
                puestos={getEstablecimiento(record.IdEstablecimientoEmpresa)?.Puestos}
                PuestosAfectados={record.PuestosAfectados}
              />
              : ''}
          </Card>
          <Card title="Equipos Radiologicos" type="inner" style={{ marginBottom: 16 }}>
            {record.EquiposRadiologicos?.length > 0
              ? <AnexoVEquiposRadiologicosTable EquiposRadiologicos={record.EquiposRadiologicos} />
              : ''}
          </Card>
          <Card title="Proveedores" type="inner" style={{ marginBottom: 16 }}>
            {record.Proveedores?.length > 0
              ? <AnexoVProveedoresTable Proveedores={record.Proveedores} />
              : ''}
          </Card>
          <Card title="Compradores" type="inner" style={{ marginBottom: 16 }}>
            {record.Compradores?.length > 0
              ? <AnexoVCompradoresTable Compradores={record.Compradores} />
              : ''}
          </Card>
          <Card title="Estudios Ambientales Especificos" type="inner" style={{ marginBottom: 16 }}>
            {record.EstudiosAmbientalesEspecificos?.length > 0
              ? <AnexoVEstudiosAmbientalesEspecificosTable EstudiosAmbientalesEspecificos={record.EstudiosAmbientalesEspecificos} />
              : ''}
          </Card>
          <Card title="Estudios Biologicos Especificos" type="inner" style={{ marginBottom: 16 }}>
            {record.EstudiosBiologicosEspecificos?.length > 0
              ? <AnexoVEstudiosBiologicosEspecificosTable EstudiosBiologicosEspecificos={record.EstudiosBiologicosEspecificos} />
              : ''}
          </Card>
        </Card>,
      }}
    />
  );
}

export default AnexoVSustanciasTable;