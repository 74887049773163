import React from "react";
import { Space, Table } from "antd";
import parser from "../../../../../shared/helpers/parser";
import DeleteButton from "../../../../../shared/components/deleteButton";
import EditButton from "../../../../../shared/components/editButton";

const AnexoVProveedoresTable = props => {
  return (
    <Table
      size="small"
      columns={ [
        { title: 'Id', dataIndex: 'IdProveedor', key: 'IdProveedor' },
        //{ title: 'Sustancia Declarada', dataIndex: 'IdSustanciaDeclarada', key: 'IdSustanciaDeclarada' },
        { title: 'Cuit', dataIndex: 'Cuit', key: 'Cuit' },
        { title: 'Nombre Comercial', dataIndex: 'NombreComercial', key: 'NombreComercial' },
        //{ title: 'Fecha Insert', dataIndex: 'FechaInsert', key: 'FechaInsert', render: text => <span>{ parser.date(text) }</span> },
        props.formMode ? { title: '', dataIndex: 'Actions', key: 'Actions',
          render: (text, record) => {
            return <Space size="small">
              <EditButton onClick={ () => props.onEdit(record) }/>
              <DeleteButton onConfirm={ () => props.onRemove(record) }/>
              {/* { !record.IdProveedor ? <DeleteButton onConfirm={ () => props.onRemove(record) }/> : '' } */}
            </Space>
          }
        } : {},
      ] } 
      dataSource={ props.Proveedores }
      rowKey={ (record, index) => index }
      pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
    />
  );
}

export default AnexoVProveedoresTable;