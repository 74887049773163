import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import LoginPage from "./components/loginPage/LoginPage";

const LoginContainer = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`} component={ LoginPage} />
    </Switch>
  );
}

export default LoginContainer;
