import React, { useEffect, useState } from "react";
import { Descriptions, Card, Timeline, Modal, Tooltip, PageHeader, Spin, Button, Popover, notification, Row, Tag } from "antd";
import { CheckCircleOutlined, ClusterOutlined, ExclamationCircleOutlined, MenuOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import parser from "../../../../shared/helpers/parser";
import { useHistory } from "react-router";
import PresentacionesApi from "../../api/PresentacionesApi";
import EditButton from "../../../../shared/components/editButton";
import ViewButton from "../../../../shared/components/viewButton";
import ReferenciasApi from "../../../referencias/api/ReferenciasApi";
import Utils from "../../../../shared/helpers/utils";
import AuthenticationHelper from "../../../../shared/helpers/authenticationHelper";

const DescItem = Descriptions.Item;
const TimeItem = Timeline.Item;

const PresentacionView = props => {
  const history = useHistory();
  const [presentacion, setPresentacion] = useState(false);
  const [motivos, setMotivos] = useState([]);
  const [programasMuestra, setProgramasMuestra] = useState([]);
  const [tiposOrganismo, setTiposOrganismo] = useState([]);
  const [estados, setEstados] = useState([]);
  const [working, setWorking] = useState(false);
  const idsEst = JSON.parse(AuthenticationHelper.getIdsEstablecimientosUsuario(AuthenticationHelper.getId()));
  const tipoUsuario = AuthenticationHelper.getTipoUsuario().toString();
  let establecimientosUsuario = [];
  let empresasTercerizadasUsuario = [];

  useEffect(() => {
    loadPresentacion();
    loadTablas();
  }, []);

  const loadTablas = () => {
    ReferenciasApi.GetTabla("SVCC.TMOTIVOS_PRESENTACIONES")
    .then(response1 => { 
      setMotivos(response1); 
      ReferenciasApi.GetTabla("MATRIZUNICA.PROGRAMASMUESTRAS").then(response => { setProgramasMuestra(response); });
      ReferenciasApi.GetTabla("TIPOSORGANISMO").then(response => { setTiposOrganismo(response); });
      ReferenciasApi.GetTabla("SVCC.TESTADOS").then(response => { setEstados(response); });
    })
    .catch((error) => {
      notification.error({ message: 'Error', description: '¡Los servicios web de la Super Intendencia están fuera de servicio!', placement: 'bottomRight' });
    });
  }

  const loadPresentacion = () => {
    setWorking(true);
    PresentacionesApi.Get(props.match.params.id, props.match.params.cuit)
      .then((response) => {
        if (tipoUsuario == "Operador") {
          let presentacion = response;
          idsEst.forEach(itemId => {
            presentacion.AnexoI.EstablecimientosDeclarados.forEach(itemP => {
              if(itemP.IdEstablecimientoEmpresa == itemId){
                establecimientosUsuario.push(itemP);
              }
            });
            presentacion.AnexoI.EmpresasTercerizadas.forEach(itemP => {
              if(itemP.IdEstablecimientoEmpresa == itemId){
                empresasTercerizadasUsuario.push(itemP);
              }
            });
          });
          setWorking(false);

          presentacion.AnexoI.EstablecimientosDeclarados.splice(0);
          presentacion.AnexoI.EmpresasTercerizadas.splice(0);

          establecimientosUsuario.forEach(item => {
            presentacion.AnexoI.EstablecimientosDeclarados.push(item);
          });
          empresasTercerizadasUsuario.forEach(item => {
            presentacion.AnexoI.EmpresasTercerizadas.push(item);
          });
          setPresentacion(presentacion);
        } else {
          setWorking(false);
          setPresentacion(response);
        }

      })
      .catch((error) => {
        setWorking(false);

        if (error.response?.data?.error?.indexOf('No se encontró una presentación para el id ' + props.match.params.id) > -1) {
          Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            content: <span>No se encontró la presentación. ¿Desea eliminarla del listado?</span>,
            onOk() {
              PresentacionesApi.Eliminar(props.match.params.id)
                .then((response) => {
                  notification.success({ message: 'Exito', description: 'Presentación eliminada', placement: 'bottomRight' });
                  history.goBack();
                })
                .catch((error) => { });
            },
            onCancel() { history.goBack(); },
          });
        }
        else {
          history.goBack();
        }
      });
  }

  const onConsolidarClick = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: <span>¿Está seguro de querer consolidar la presentación?</span>,
      onOk() {
        setWorking(true);
        let data = {
          Cuit: props.match.params.cuit,
          IdPresentacion: props.match.params.id
        }
        PresentacionesApi.Consolidar(data)
          .then((response) => {
            setWorking(false);
            if(response == "ok"){
              notification.success({ message: 'Exito', description: 'Presentación consolidada', placement: 'bottomRight' });
              notification.warning({ message: '¡Alerta!', description: '¡Posee 10 dias habiles para modificar la presentacion!', placement: 'bottomRight' })
            }else{
              notification.error({ message: 'Error', description: '¡No se pudo consolidar la presentacion!', placement: 'bottomRight' });
              notification.error({ message: 'Error', description: response, placement: 'bottomRight' });
            }
            loadPresentacion();
          })
          .catch((error) => { setWorking(false); });
      },
      onCancel() { },
    });
  };

  const onCancelarClick = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: <span>¿Está seguro de querer cancelar la presentación?</span>,
      onOk() {
        setWorking(true);
        let data = {
          Cuit: props.match.params.cuit,
          IdPresentacion: props.match.params.id
        }
        PresentacionesApi.Cancelar(data)
          .then((response) => {
            setWorking(false);
            notification.success({ message: 'Exito', description: 'Presentación cancelada', placement: 'bottomRight' });
            history.goBack();
          })
          .catch((error) => { setWorking(false); });
      },
      onCancel() { },
    });
  };

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <PageHeader
        className="site-page-header"
        title="Presentación"
        onBack={() => history.goBack()}
        extra={[
          <Popover key="1" placement="bottomRight" trigger="click"
            content={<div>
              <Button key="1" style={{ textAlign: 'left', marginBottom: 8, width: '100%' }}
                icon={<ClusterOutlined />}
                disabled={presentacion?.Nomina === null || parser.datetime(presentacion?.FechaDeConsolidacion) !== ''}
                onClick={() => onConsolidarClick()}
              >Consolidar</Button>
              <br />
              <Button key="2" style={{ textAlign: 'left', marginBottom: 8, width: '100%' }}
                icon={<CloseOutlined />}
                onClick={() => onCancelarClick()}
              >Cancelar</Button>
            </div>
            }
          >
          {
            tipoUsuario!=="Operador" ?
            <>
            <Tooltip title="Menu" placement="left"><MenuOutlined style={{ color: '#f15e21' }} /></Tooltip>
            </>:null
          }
          </Popover>,
        ]}
      />
      <div style={{ padding: 16, minHeight: 690 }}>
        <Spin spinning={working}>

          <Card title={`Id Presentacion: ${presentacion?.PresentacionId}`}>
            <Descriptions size="small" column={1} bordered>
              <DescItem label="Numero de Poliza"><b>{presentacion?.NumeroDePoliza}</b></DescItem>
              <DescItem label="Cuit"><b>{presentacion?.Cuit}</b></DescItem>
              <DescItem label="Motivo"><b>{Utils.getIdDesc(presentacion?.IdMotivo, motivos)}</b></DescItem>
              <DescItem label="Programa Muestra"><b>{Utils.getIdDesc(presentacion?.IdProgramaMuestra, programasMuestra)}</b></DescItem>
              <DescItem label="Version"><b>{presentacion?.Version}</b></DescItem>
              <DescItem label="Fecha Presentacion Empleador"><b>{parser.date(presentacion?.FechaPresentacionEmpleador)}</b></DescItem>
              <DescItem label="Fecha de Consolidacion"><b>{parser.datetime(presentacion?.FechaDeConsolidacion)}</b></DescItem>
              <DescItem label="Tipo Organismo"><b>{Utils.getIdDesc(presentacion?.IdTipoOrganismo, tiposOrganismo)}</b></DescItem>
              <DescItem label="Id Organismo"><b>{presentacion?.IdOrganismo}</b></DescItem>

              <DescItem label="Anexo I"><b>{
                presentacion?.AnexoI != null
                  ? <Row justify="space-between" align="middle">
                    <Tag style={{ padding: 8, minWidth: 155 }} icon={<CheckCircleOutlined />} color="success">Anexo I presentado</Tag>
                    {
                      tipoUsuario == "Operador" ?
                        <>
                          <ViewButton onClick={() => history.push({ pathname: '/anexoi/edit/' + presentacion?.Cuit, presentacion: presentacion, anexoi: presentacion.AnexoI, anexov: presentacion.AnexoV, Nomina: presentacion.Nomina })} />
                        </> :
                        <>
                          <EditButton onClick={() => history.push({ pathname: '/anexoi/edit/' + presentacion?.Cuit, presentacion: presentacion, anexoi: presentacion.AnexoI, anexov: presentacion.AnexoV, Nomina: presentacion.Nomina })} />
                        </>
                    }
                  </Row>
                  : <Button type="primary" icon={<PlusOutlined />}
                    onClick={() => history.push({ pathname: '/anexoi/new/' + presentacion?.Cuit })}
                  >Ingresar</Button>
              }</b></DescItem>
              <DescItem label="Anexo V"><b>{
                presentacion?.AnexoI != null
                  ? presentacion?.AnexoV != null
                    ? <Row justify="space-between" align="middle">
                      <Tag style={{ padding: 8, minWidth: 155 }} icon={<CheckCircleOutlined />} color="success">Anexo V presentado</Tag>
                      {
                        tipoUsuario == "Operador" ?
                          <>
                            <ViewButton onClick={() => history.push({ pathname: '/anexov/edit/' + presentacion?.Cuit, anexoi: presentacion.AnexoI, anexov: presentacion.AnexoV })} />
                          </> :
                          <>
                            <EditButton onClick={() => history.push({ pathname: '/anexov/edit/' + presentacion?.Cuit, anexoi: presentacion.AnexoI, anexov: presentacion.AnexoV })} />
                          </>
                      }
                    </Row>
                    : <Button type="primary" icon={<PlusOutlined />}
                      onClick={() => history.push({ pathname: '/anexov/new/' + presentacion?.Cuit, anexoi: presentacion.AnexoI })}
                    >Ingresar</Button>
                  : <Tag style={{ padding: 8, minWidth: 180 }} icon={<ExclamationCircleOutlined />} color="warning">Debe ingresar el Anexo I</Tag>
              }</b></DescItem>
              <DescItem label="Nomina"><b>{
                presentacion?.AnexoV != null
                  ? presentacion?.Nomina != null
                    ? <Row justify="space-between" align="middle">
                      <Tag style={{ padding: 8, minWidth: 155 }} icon={<CheckCircleOutlined />} color="success">Nomina presentada</Tag>
                      <EditButton onClick={() => history.push({ pathname: '/nomina/edit/' + presentacion?.Cuit, anexoi: presentacion.AnexoI, anexov: presentacion.AnexoV, nomina: presentacion.Nomina })} />
                    </Row>
                    : <Button type="primary" icon={<PlusOutlined />}
                      onClick={() => history.push({ pathname: '/nomina/new/' + presentacion?.Cuit, anexoi: presentacion.AnexoI, anexov: presentacion.AnexoV })}
                    >Ingresar</Button>
                  : <Tag style={{ padding: 8, minWidth: 180 }} icon={<ExclamationCircleOutlined />} color="warning">Debe ingresar el Anexo V</Tag>
              }</b></DescItem>

              <DescItem label="Observaciones"><b>{presentacion?.Observaciones}</b></DescItem>

              <DescItem label="Seguimiento de Estados">
                <Timeline style={{ marginTop: 64 }}>
                  {presentacion?.SeguimientoEstados?.sort((a, b) => new Date(a.Fecha) - new Date(b.Fecha)) /*(a.IdEstado > b.IdEstado) ? 1 : ((b.IdEstado > a.IdEstado) ? -1 : 0))*/
                    .map(estado => (
                      <TimeItem key={estado.IdEstado}>
                        <p style={{ margin: 0 }}>Estado: {Utils.getIdDesc(estado.IdEstado, estados)}</p>
                        <p style={{ margin: 0 }}><small style={{ color: '#f15e21' }}>{parser.datetime(estado.Fecha)}</small></p>
                      </TimeItem>
                    ))}
                </Timeline>
              </DescItem>
            </Descriptions>
          </Card>

        </Spin>
      </div>
    </div>
  );
}

export default PresentacionView;