import React, { useEffect, useState } from "react";
import { Form, Input, Spin, Collapse, Modal, Checkbox, InputNumber, Tooltip, notification } from "antd";
import { PlusCircleTwoTone, EditTwoTone } from '@ant-design/icons';
import { FormConfig } from "../../../../../shared/config/GlobalConfig";
import Utils from "../../../../../shared/helpers/utils";
import ReferenciasApi from "../../../../referencias/api/ReferenciasApi";
import AnexoVDosimetriaView from "../../view/sustancias/equipos_radiologicos/AnexoVDosimetriaView";
import AnexoVDosimetriaModal from "./equipos_radiologicos/AnexoVDosimetriaModal";
import AnexoVResponsablesDeUsoTable from "../../view/sustancias/equipos_radiologicos/AnexoVResponsablesDeUsoTable";
import AnexoVResponsableDeUsoModal from "./equipos_radiologicos/AnexoVResponsableDeUsoModal";
import AnexoVResponsablesDeInstalacionTable from "../../view/sustancias/equipos_radiologicos/AnexoVResponsablesDeInstalacionTable";
import AnexoVResponsableDeInstalacionModal from "./equipos_radiologicos/AnexoVResponsableDeInstalacionModal";
import AnexoVEncargadosDeProteccionRadiologicaTable from "../../view/sustancias/equipos_radiologicos/AnexoVEncargadosDeProteccionRadiologicaTable";
import AnexoVEncargadoDeProteccionRadiologicaModal from "./equipos_radiologicos/AnexoVEncargadoDeProteccionRadiologicaModal";
import ReferenciaSelectFormItem from '../../../../../shared/components/referenciaSelectFormItem';
import CampoObligatorio from "../../../../../shared/components/campoObligatorio";
import AuthenticationHelper from "../../../../../shared/helpers/authenticationHelper";

const FormItem = Form.Item;
const { Panel } = Collapse;

const AnexoVEquipoRadiologicoModal = props => {
  const [tiposEquipo, setTiposEquipo] = useState([]);
  const [caracteristicasEquipo, setCaracteristicasEquipo] = useState([]);
  const [tiposRadiacion, setTiposRadiacion] = useState([]);
  const [tiposFuente, setTiposFuente] = useState([]);
  const [metodosProcesamiento, setMetodosProcesamiento] = useState([]);

  const [dosimetria, setDosimetria] = useState(undefined);
  const [responsablesDeUso, setResponsablesDeUso] = useState([]);
  const [responsablesDeInstalacion, setResponsablesDeInstalacion] = useState([]);
  const [encargadosDeProteccionRadiologica, setEncargadosDeProteccionRadiologica] = useState([]);

  const [showDosimetriaModal, setShowDosimetriaModal] = useState(false);
  const [showResponsableDeUsoModal, setShowResponsableDeUsoModal] = useState(false);
  const [showResponsableDeInstalacionModal, setShowResponsableDeInstalacionModal] = useState(false);
  const [showEncargadoDeProteccionRadiologicaModal, setShowEncargadoDeProteccionRadiologicaModal] = useState(false);

  //const [selectedDosimetria, setSelectedDosimetria] = useState(undefined);
  const [selectedResponsableDeUso, setSelectedResponsableDeUso] = useState(undefined);
  const [selectedResponsableDeInstalacion, setSelectedResponsableDeInstalacion] = useState(undefined);
  const [selectedEncargadoDeProteccionRadiologica, setSelectedEncargadoDeProteccionRadiologica] = useState(undefined);

  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    ReferenciasApi.GetTabla("SVCC.TTIPOS_EQUIPOS").then(response => { setTiposEquipo(response); });
    ReferenciasApi.GetTabla("SVCC.TCARACTERISTICAS_EQUIPOS").then(response => { setCaracteristicasEquipo(response); });
    ReferenciasApi.GetTabla("SVCC.TTIPOS_RADIACIONES").then(response => { setTiposRadiacion(response); });
    ReferenciasApi.GetTabla("SVCC.TTIPOS_FUENTES_EQUIPOS").then(response => { setTiposFuente(response); });
    ReferenciasApi.GetTabla("SVCC.TMETODOS_PROCESADOS_IMAGENES").then(response => { setMetodosProcesamiento(response); });
  }, []);

  useEffect(() => {
    if (props.visible === true) {
      form.resetFields();
      setDosimetria(props.equipo ? props.equipo.Dosimetria : undefined);
      setResponsablesDeUso(props.equipo ? props.equipo.ResponsablesDeUso : []);
      setResponsablesDeInstalacion(props.equipo ? props.equipo.ResponsablesDeInstalacion : []);
      setEncargadosDeProteccionRadiologica(props.equipo ? props.equipo.EncargadosDeProteccionRadiologica : []);
    }
  }, [props.visible]);

  const handleOk = () => {
    form.validateFields()
      .then(values => {
        props.onOk({
          Hash: props.equipo ? props.equipo.Hash : Utils.generateHash(),
          ...values,
          Dosimetria: dosimetria,
          ResponsablesDeUso: responsablesDeUso,
          ResponsablesDeInstalacion: responsablesDeInstalacion,
          EncargadosDeProteccionRadiologica: encargadosDeProteccionRadiologica,
        });
      })
      .catch(error => {
        notification.warning({ message: 'Error de validación', description: 'Revise los campos del formulario', placement: 'bottomRight' });
      });
  }

  return (
    <Modal
      title={`${props.equipo ? 'Editar' : 'Agregar'} Equipo Radiologico`}
      width="80%"
      visible={props.visible}
      onOk={handleOk}
      onCancel={props.onCancel}
    >
      <Spin spinning={working}>
        <Form
          {...FormConfig.DefaultLayout}
          form={form}
          name="formulario"
          initialValues={{
            ...props.equipo,
            IdSustanciaDeclarada: props.equipo?.IdSustanciaDeclarada ? props.equipo.IdSustanciaDeclarada : props.sustancia?.IdSustanciaDeclarada,
            ProcesamientoImagenes: props.equipo ? props.equipo.ProcesamientoImagenes : false,
          }}
          scrollToFirstError
        >
          <Collapse defaultActiveKey="1">
            <Panel header="Equipo Radiologico" key="1">
              {props.equipo?.IdEquipoRadiologico ? <FormItem name="IdEquipoRadiologico" label="Equipo Radiologico" rules={FormConfig.DefaultRules}>
                <Input disabled />
              </FormItem> : ''}

              {props.sustancia?.IdSustanciaDeclarada ? <FormItem name="IdSustanciaDeclarada" label="Sustancia Declarada" rules={FormConfig.DefaultRules}>
                <Input disabled />
              </FormItem> : ''}

              <FormItem name="Marca" label="Marca" rules={FormConfig.DefaultRules}>
                <Input />
              </FormItem>

              <FormItem name="Modelo" label="Modelo" rules={FormConfig.DefaultRules}>
                <Input />
              </FormItem>

              <ReferenciaSelectFormItem name="IdTipoEquipo" label="Tipo Equipo"
                rules={FormConfig.DefaultRules}
                tabla={tiposEquipo}
              />

              <ReferenciaSelectFormItem name="IdCaracteristicaEquipo" label="Caracteristica Equipo"
                rules={FormConfig.DefaultRules}
                tabla={caracteristicasEquipo}
              />

              <ReferenciaSelectFormItem name="IdTipoRadiacion" label="Tipo Radiacion"
                rules={FormConfig.DefaultRules}
                tabla={tiposRadiacion}
              />

              <FormItem name="Actividad" label="Actividad" rules={FormConfig.DefaultRules}>
                <Input />
              </FormItem>

              <FormItem name="RadioisotopoFuente" label="Radioisotopo Fuente" rules={FormConfig.DefaultRules}>
                <Input />
              </FormItem>

              <ReferenciaSelectFormItem name="IdTipoFuente" label="Tipo Fuente"
                rules={FormConfig.DefaultRules}
                tabla={tiposFuente}
              />

              <FormItem name="PotenciaEquipoKVP" label="Potencia Equipo KVP" rules={FormConfig.DefaultRules}>
                <InputNumber style={{ width: '100%' }} />
              </FormItem>

              <FormItem name="PotenciaEquipomAp" label="Potencia Equipo mAp" rules={FormConfig.DefaultRules}>
                <InputNumber style={{ width: '100%' }} />
              </FormItem>

              <FormItem name="PotenciaEquipoMeV" label="Potencia Equipo MeV" rules={FormConfig.DefaultRules}>
                <InputNumber style={{ width: '100%' }} />
              </FormItem>

              <FormItem name="ProcesamientoImagenes" label="Procesamiento Imagenes" valuePropName="checked">
                <Checkbox />
              </FormItem>

              <ReferenciaSelectFormItem name="MetodoProcesamiento" label="Metodo Procesamiento"
                rules={FormConfig.DefaultRules}
                tabla={metodosProcesamiento}
              />

              <FormItem name="Observaciones" label="Observaciones" rules={FormConfig.DefaultRules}>
                <Input />
              </FormItem>
            </Panel>

            <Panel header="Dosimetria" key="2"
              extra={<div>
                <Tooltip title={dosimetria ? 'Editar' : 'Agregar'} placement="left">
                  {dosimetria
                    ? <EditTwoTone twoToneColor="#f15e21" onClick={event => { setShowDosimetriaModal(true); event.stopPropagation(); }} />
                    : <PlusCircleTwoTone twoToneColor="#f15e21" onClick={event => { setShowDosimetriaModal(true); event.stopPropagation(); }} />
                  }
                </Tooltip>
              </div>}
            >
              {dosimetria
                ? <div style={{ overflow: 'auto', width: '100%' }}>
                  <AnexoVDosimetriaView
                    Dosimetria={dosimetria}
                  //onRemove={ record => { setDosimetria(undefined); } }
                  /></div>
                : ''}
            </Panel>

            <Panel header="Responsables De Uso" key="3"
              extra={<Tooltip title="Agregar" placement="left"><PlusCircleTwoTone twoToneColor="#f15e21"
                onClick={event => {
                  event.stopPropagation();
                  setSelectedResponsableDeUso(undefined);
                  setShowResponsableDeUsoModal(true);
                }}
              /></Tooltip>}
            >
              {responsablesDeUso.length > 0
                ? <div style={{ overflow: 'auto', width: '100%' }}>
                  <AnexoVResponsablesDeUsoTable formMode
                    ResponsablesDeUso={responsablesDeUso}
                    onRemove={record => { 
                      setResponsablesDeUso([...responsablesDeUso.filter(item => (item.IdResponsableDeUso !== record.IdResponsableDeUso))]); 
                      let datos = AuthenticationHelper.getDatosRemove();
                      datos.ResponsablesDeUso.push(record.IdResponsableDeUso);
                      AuthenticationHelper.storeDatosRemove(datos);
                      }}
                    onEdit={record => {
                      setSelectedResponsableDeUso(record);
                      setShowResponsableDeUsoModal(true);
                    }}
                  /></div>
                : ''}
            </Panel>

            <Panel header="Responsables De Instalacion" key="4"
              extra={<Tooltip title="Agregar" placement="left"><PlusCircleTwoTone twoToneColor="#f15e21"
                onClick={event => {
                  event.stopPropagation();
                  setSelectedResponsableDeInstalacion(undefined);
                  setShowResponsableDeInstalacionModal(true);
                }}
              /></Tooltip>}
            >
              {responsablesDeInstalacion.length > 0
                ? <div style={{ overflow: 'auto', width: '100%' }}>
                  <AnexoVResponsablesDeInstalacionTable formMode
                    ResponsablesDeInstalacion={responsablesDeInstalacion}
                    onRemove={record => { 
                      setResponsablesDeInstalacion([...responsablesDeInstalacion.filter(item => (item.IdResponsableDeInstalacion !== record.IdResponsableDeInstalacion))]); 
                      let datos = AuthenticationHelper.getDatosRemove();
                      datos.ResponsablesDeInstalacion.push(record.IdResponsableDeInstalacion);
                      AuthenticationHelper.storeDatosRemove(datos);
                      }}
                    onEdit={record => {
                      setSelectedResponsableDeInstalacion(record);
                      setShowResponsableDeInstalacionModal(true);
                    }}
                  /></div>
                : ''}
            </Panel>

            <Panel header="Encargados De Proteccion Radiologica" key="5"
              extra={<Tooltip title="Agregar" placement="left"><PlusCircleTwoTone twoToneColor="#f15e21"
                onClick={event => {
                  event.stopPropagation();
                  setSelectedEncargadoDeProteccionRadiologica(undefined);
                  setShowEncargadoDeProteccionRadiologicaModal(true);
                }}
              /></Tooltip>}
            >
              {encargadosDeProteccionRadiologica.length > 0
                ? <div style={{ overflow: 'auto', width: '100%' }}>
                  <AnexoVEncargadosDeProteccionRadiologicaTable formMode
                    EncargadosDeProteccionRadiologica={encargadosDeProteccionRadiologica}
                    onRemove={record => { 
                      setEncargadosDeProteccionRadiologica([...encargadosDeProteccionRadiologica.filter(item => (item.IdEncargadoDeProteccionRadiologica !== record.IdEncargadoDeProteccionRadiologica))]); 
                      let datos = AuthenticationHelper.getDatosRemove();
                      datos.EncargadosDeProteccionRadiologica.push(record.IdEncargadoDeProteccionRadiologica);
                      AuthenticationHelper.storeDatosRemove(datos);
                      }}
                    onEdit={record => {
                      setSelectedEncargadoDeProteccionRadiologica(record);
                      setShowEncargadoDeProteccionRadiologicaModal(true);
                    }}
                  /></div>
                : ''}
            </Panel>
          </Collapse>

          <CampoObligatorio/>
          
        </Form>
        {
          showDosimetriaModal ?
            <>
              <AnexoVDosimetriaModal
                visible={showDosimetriaModal}
                dosimetria={dosimetria}
                equipo={props.equipo}
                onOk={dosimetria => {
                  setDosimetria(dosimetria);
                  setShowDosimetriaModal(false);
                }}
                onCancel={() => setShowDosimetriaModal(false)}
              />
            </> : null
        }

        {
          showResponsableDeUsoModal ?
            <>
              <AnexoVResponsableDeUsoModal
                visible={showResponsableDeUsoModal}
                responsable={selectedResponsableDeUso}
                equipo={props.equipo}
                onOk={responsable => {
                  let arr = [...responsablesDeUso];
                  let idx = arr.findIndex(x => x.Hash ? x.Hash === responsable.Hash : x.IdResponsableDeUso === responsable.IdResponsableDeUso);
                  if (idx !== -1) { arr[idx] = responsable; }
                  else { arr.push(responsable); }
                  setResponsablesDeUso([...arr]);
                  setShowResponsableDeUsoModal(false);
                }}
                onCancel={() => setShowResponsableDeUsoModal(false)}
              />
            </> : null
        }

        {
          showResponsableDeInstalacionModal ?
            <>
              <AnexoVResponsableDeInstalacionModal
                visible={showResponsableDeInstalacionModal}
                responsable={selectedResponsableDeInstalacion}
                equipo={props.equipo}
                onOk={responsable => {
                  let arr = [...responsablesDeInstalacion];
                  let idx = arr.findIndex(x => x.Hash ? x.Hash === responsable.Hash : x.IdResponsableDeInstalacion === responsable.IdResponsableDeInstalacion);
                  if (idx !== -1) { arr[idx] = responsable; }
                  else { arr.push(responsable); }
                  setResponsablesDeInstalacion([...arr]);
                  setShowResponsableDeInstalacionModal(false);
                }}
                onCancel={() => setShowResponsableDeInstalacionModal(false)}
              />
            </> : null
        }

        {
          showEncargadoDeProteccionRadiologicaModal ?
            <>
              <AnexoVEncargadoDeProteccionRadiologicaModal
                visible={showEncargadoDeProteccionRadiologicaModal}
                encargado={selectedEncargadoDeProteccionRadiologica}
                equipo={props.equipo}
                onOk={encargado => {
                  let arr = [...encargadosDeProteccionRadiologica];
                  let idx = arr.findIndex(x => x.Hash ? x.Hash === encargado.Hash : x.IdEncargadoDeProteccionRadiologica === encargado.IdEncargadoDeProteccionRadiologica);
                  if (idx !== -1) { arr[idx] = encargado; }
                  else { arr.push(encargado); }
                  setEncargadosDeProteccionRadiologica([...arr]);
                  setShowEncargadoDeProteccionRadiologicaModal(false);
                }}
                onCancel={() => setShowEncargadoDeProteccionRadiologicaModal(false)}
              />
            </> : null
        }

      </Spin>
    </Modal>
  );
}

export default AnexoVEquipoRadiologicoModal;