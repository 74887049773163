import React, { Fragment } from 'react';

const CampoObligatorio = () => {
    return (
        <center>
            <p />
            <h style={{ color: '#FF5733' }}>* Estos campos son obligatorios</h>
            <p />
        </center>
    );
};

export default CampoObligatorio;

