import React, { useEffect, useState } from "react";
import { Form, Input, Spin, Modal } from "antd";
import { FormConfig } from "../../../../../shared/config/GlobalConfig";
import ReferenciasApi from "../../../../referencias/api/ReferenciasApi";
import Utils from "../../../../../shared/helpers/utils";
import ReferenciaSelectFormItem from "../../../../../shared/components/referenciaSelectFormItem";
import CampoObligatorio from "../../../../../shared/components/campoObligatorio";

const FormItem = Form.Item;

const AnexoISectorModal = props => {
  const [ciius, setCiius] = useState([]);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    ReferenciasApi.GetTabla("PUBLICACIONES.CIIU").then((response) => { setCiius(response); })
  }, []);

  useEffect(() => {
    if(props.visible === true){ form.resetFields(); }
  }, [props.visible]);

  const handleOk = () => {
    form.validateFields()
      .then(values => {
        props.onOk({ 
          Hash: props.sector ? props.sector.Hash : Utils.generateHash(), 
          ...values 
        });
      });
  }

  return (
    <Modal 
      title={`${props.sector ? 'Editar' : 'Agregar'} Sector`}
      width="60%"
      visible={ props.visible }
      onOk={ handleOk }
      onCancel={ props.onCancel }
    >
      <Spin spinning={ working }>
        <Form
          { ...FormConfig.DefaultLayout }
          form={ form }
          name="formulario"
          initialValues={{ ...props.sector }}
          scrollToFirstError
        >
          { props.sector?.IdSector ? <FormItem name="IdSector" label="Sector" rules={ FormConfig.DefaultRules }>
            <Input disabled/>
          </FormItem> : '' }

          <FormItem name="Nombre" label="Nombre" rules={ FormConfig.DefaultRules }>
            <Input />
          </FormItem>

          <ReferenciaSelectFormItem name="Ciiu" label="Ciiu" 
            rules={ FormConfig.DefaultRules } 
            tabla={ ciius }
          />

          <CampoObligatorio/>
          
        </Form>
      </Spin>
    </Modal>
  );
}

export default AnexoISectorModal;