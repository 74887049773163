import React, { useEffect, useState } from "react";
import { Form, Spin, Modal, Input } from "antd";
import Utils from "../../../../../../shared/helpers/utils";
import { FormConfig } from "../../../../../../shared/config/GlobalConfig";
import CuitFormItem from '../../../../../../shared/components/cuitFormItem';
import CampoObligatorio from "../../../../../../shared/components/campoObligatorio";

const FormItem = Form.Item;

const AnexoVEncargadosDeProteccionRadiologicaModal = props => {
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    
  }, []);

  useEffect(() => {
    if(props.visible === true){ form.resetFields(); }
  }, [props.visible]);

  const handleOk = () => {
    form.validateFields()
      .then(values => {
        props.onOk({ 
          Hash: props.encargado ? props.encargado.Hash : Utils.generateHash(), 
          ...values 
        });
      });
  }

  return (
    <Modal 
      title={`${props.encargado ? 'Editar' : 'Agregar'} Encargado de Proteccion Radiologica`}
      width="60%"
      visible={ props.visible }
      onOk={ handleOk }
      onCancel={ props.onCancel }
    >
      <Spin spinning={ working }>
        <Form
          { ...FormConfig.DefaultLayout }
          form={ form }
          name="formulario"
          initialValues={{ ...props.encargado,
            IdEquipoRadiologico: props.encargado?.IdEquipoRadiologico ? props.encargado.IdEquipoRadiologico : props.equipo?.IdEquipoRadiologico,
          }}
          scrollToFirstError
        >
          { props.encargado?.IdEncargadoDeProteccionRadiologica ? <FormItem name="IdEncargadoDeProteccionRadiologica" label="Encargado de Proteccion Radiologica" rules={ FormConfig.DefaultRules }>
            <Input disabled/>
          </FormItem> : '' }

          { props.equipo?.IdEquipoRadiologico ? <FormItem name="IdEquipoRadiologico" label="Equipo Radiologico" rules={ FormConfig.DefaultRules }>
            <Input disabled/>
          </FormItem> : '' }

          <CuitFormItem name="Cuil" label="Cuil" rules={ [...FormConfig.DefaultRules] } />

          <FormItem name="TituloProfesional" label="Titulo Profesional" rules={ FormConfig.DefaultRules }>
            <Input />
          </FormItem>

          <FormItem name="MatriculaProfesional" label="Matricula Profesional" rules={ FormConfig.DefaultRules }>
            <Input />
          </FormItem>

          <FormItem name="NroHabilitacion" label="Nro Habilitacion" rules={ FormConfig.DefaultRules }>
            <Input />
          </FormItem>

          <CampoObligatorio/>
          
        </Form>
      </Spin>
    </Modal>
  );
}

export default AnexoVEncargadosDeProteccionRadiologicaModal;