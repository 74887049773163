import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import { Route } from "react-router-dom";
import UsuariosList from "./components/list/UsuariosList";
import UsuarioForm from "./components/form/UsuarioForm";

const UsuariosPage = () => {
    let { path } = useRouteMatch();
    return (
      <Switch>
        <Route exact path={`${path}/`} component={ UsuariosList } />
        <Route exact path={`${path}/new`} component={ UsuarioForm } />
        <Route exact path={`${path}/update_:Id`} component={ UsuarioForm } />
      </Switch>
    );

};

export default UsuariosPage;