import React from "react";
import { Form, Input, Select } from "antd";
import { FormConfig } from "../config/GlobalConfig";

const FormItem = Form.Item;
const { Option } = Select;

const EstablecimientoEmpresaSelectFormItem = props => {
  return (
    <FormItem { ...props }>
      <Select showSearch filterOption={ FormConfig.DefaultSelectFilter }>
        { props.establecimientosEmpresa.map(empresa => {
          return <Option key={ empresa.Codigo } value={ empresa.Codigo }>{ `${empresa.Establecimiento.Codigo} - ${empresa.Establecimiento.Descripcion}` }</Option>
        }) }
      </Select>
    </FormItem>
  );
}

export default EstablecimientoEmpresaSelectFormItem;