import React, { useEffect, useState } from "react";
import { Form, Spin, Modal, Input, InputNumber } from "antd";
import Utils from "../../../../../../shared/helpers/utils";
import { FormConfig } from "../../../../../../shared/config/GlobalConfig";
import CuitFormItem from '../../../../../../shared/components/cuitFormItem';
import CampoObligatorio from "../../../../../../shared/components/campoObligatorio";

const FormItem = Form.Item;

const AnexoVDosimetriaModal = props => {
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
  }, []);

  useEffect(() => {
    if(props.visible === true){ form.resetFields(); }
  }, [props.visible]);

  const handleOk = () => {
    form.validateFields()
      .then(values => {
        props.onOk({ 
          Hash: props.dosimetria ? props.dosimetria.Hash : Utils.generateHash(), 
          ...values 
        });
      });
  }

  return (
    <Modal 
      title={`${props.dosimetria ? 'Editar' : 'Agregar'} Dosimetria`}
      width="60%"
      visible={ props.visible }
      onOk={ handleOk }
      onCancel={ props.onCancel }
    >
      <Spin spinning={ working }>
        <Form
          { ...FormConfig.DefaultLayout }
          form={ form }
          name="formulario"
          initialValues={{ ...props.dosimetria,
            IdEquipoRadiologico: props.dosimetria?.IdEquipoRadiologico ? props.dosimetria.IdEquipoRadiologico : props.equipo?.IdEquipoRadiologico,
          }}
          scrollToFirstError
        >
          { props.dosimetria?.IdDosimetria ? <FormItem name="IdDosimetria" label="Dosimetria" rules={ FormConfig.DefaultRules }>
            <Input disabled/>
          </FormItem> : '' }

          { props.equipo?.IdEquipoRadiologico ? <FormItem name="IdEquipoRadiologico" label="Equipo Radiologico" rules={ FormConfig.DefaultRules }>
            <Input disabled/>
          </FormItem> : '' }

          <FormItem name="CantidadDePersonal" label="Cantidad de Personal" rules={ FormConfig.DefaultRules }>
            <InputNumber min={ 0 } style={ { width: '100%' } }/>
          </FormItem>

          <FormItem name="TipoDosimetria" label="Tipo Dosimetria" rules={ FormConfig.DefaultRules }>
            <Input />
          </FormItem>

          <FormItem name="MetodoDosimetria" label="Metodo Dosimetria" rules={ FormConfig.DefaultRules }>
            <Input />
          </FormItem>

          <CuitFormItem name="CuitResponsable" label="Cuit Responsable" rules={ [...FormConfig.DefaultRules] } />
          
          <CampoObligatorio/>
          
        </Form>
      </Spin>
    </Modal>
  );
}

export default AnexoVDosimetriaModal;