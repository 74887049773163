import React from 'react';
import ExcelJS from 'exceljs';
import { Button, Tooltip } from "antd";
import { FileExcelOutlined } from '@ant-design/icons';

const AnexoIExportarExcel = ({ datos, empleador }) => {
    const exportar = async () => {
        const promises = datos.map(async (establecimiento) => {
            // Crear un libro de Excel
            const workbook = new ExcelJS.Workbook();

            // Crear una hoja de Excel para los atributos que no sean de tipo array
            const worksheetGeneral = workbook.addWorksheet('ESTABLECIMIENTO');

            let establecimientoTabla = [];
            let { FECHA_PRESENTACION_EMPLEADOR, ESTABLECIMIENTO, TRAB_PROPIOS_ADMIN, TRAB_PROPIOS_PROD } = establecimiento;
            let datosEstab = { RAZON_SOCIAL: empleador.Empleador, FECHA_PRESENTACION_EMPLEADOR, ESTABLECIMIENTO, TRAB_PROPIOS_ADMIN, TRAB_PROPIOS_PROD };
            establecimientoTabla.push(datosEstab);

            // Añadir los encabezados a la tabla
            worksheetGeneral.columns = Object.keys(establecimientoTabla[0]).map((key) => ({
                header: key.replace(/_/g, ' '),
                key: key,
            }));

            // Dar formato a la fila de los encabezados
            worksheetGeneral.getRow(1).eachCell((cell) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'E6E6E6' },
                };
                cell.font = {
                    bold: true,
                };
                // Establecer el estilo de borde
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            });

            // Añadir los datos a la tabla
            worksheetGeneral.addRows([establecimientoTabla[0]]);

            worksheetGeneral.columns.forEach((column) => {
                let maxLength = 0;
                column.eachCell({ includeEmpty: true }, (cell) => {
                    const columnLength = cell.value ? cell.value.toString().length : 0;
                    maxLength = Math.max(maxLength, columnLength);
                });
                column.width = maxLength + 2; // agregar un margen adicional para que los datos se vean mejor
            });

            // Crear una hoja de Excel para cada lista dentro del objeto
            Object.keys(establecimiento).forEach((key) => {
                const value = establecimiento[key];

                if (Array.isArray(value)) {
                    // Crear una hoja de Excel para la lista
                    const worksheetList = workbook.addWorksheet(key.replace(/_/g, ' '));

                    if (value.length > 0) {
                        // Añadir los encabezados a la tabla
                        worksheetList.columns = Object.keys(value[0]).map((key) => ({
                            header: key.replace(/_/g, ' '),
                            key: key,
                        }));

                        // Dar formato a la fila de los encabezados
                        worksheetList.getRow(1).eachCell((cell) => {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'E6E6E6' },
                            };
                            cell.font = {
                                bold: true,
                            };
                            // Establecer el estilo de borde
                            cell.border = {
                                top: { style: 'thin' },
                                left: { style: 'thin' },
                                bottom: { style: 'thin' },
                                right: { style: 'thin' }
                            };
                        });

                        // Añadir los datos a la tabla
                        worksheetList.addRows(value);

                        // Autoajustar el ancho de las columnas
                        worksheetList.columns.forEach((column) => {
                            let maxLength = 0;
                            column.eachCell({ includeEmpty: true }, (cell) => {
                                const columnLength = cell.value ? cell.value.toString().length : 0;
                                maxLength = Math.max(maxLength, columnLength);
                            });
                            column.width = maxLength + 2; // agregar un margen adicional para que los datos se vean mejor
                        });
                    }
                }
            });

            // Escribir el libro de Excel en un buffer y descargarlo
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `(${empleador.Empleador}) ${establecimiento.ESTABLECIMIENTO}.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);
        });

        await Promise.all(promises);
    };

    return (
        <Tooltip
            title={'Exportar a Excel'}
            placement="left">
            <Button
                shape="circle"
                size="small"
                style={{ background: "white", color: "green", borderColor: "green" }}
                icon={<FileExcelOutlined />}
                onClick={exportar}
            />
        </Tooltip>
    );
};

export default AnexoIExportarExcel;