import React, { useEffect, useState, Fragment } from "react";
import { Form, Input, Spin, Modal, Button, notification } from "antd";
import { FormConfig } from "../config/GlobalConfig";
import UsuariosApi from "../../pages/usuarios/api/UsuariosApi";
import AuthenticationHelper from "../helpers/authenticationHelper";
import AuthApi from "../../pages/auth/api/AuthApi";
import { useHistory } from "react-router";
import ClaveFormItem from "./claveFormItem";

const FormItem = Form.Item;


const CambiarClaveModal = props => {
    let history = useHistory();
    const [working, setWorking] = useState(false);
    const [form] = Form.useForm();
    let idUsuario = AuthenticationHelper.getId();

    useEffect(() => {
        if (props.visible === true) {
            form.resetFields();
        }
    }, []);

    useEffect(() => {
        if (props.visible === true) { form.resetFields(); }
    }, [props.visible]);

    const handleOk = () => {

        var usuario = new Object();

        form.validateFields()
            .then(values => {
                if (values.Clave == values.ConfirmacionClave) {
                    AuthApi.Encrypt(values.Clave)
                        .then(responsePSW => {
                            setWorking(true);
                            UsuariosApi.Get(idUsuario)
                                .then(response => {
                                    usuario = response;
                                    usuario.Clave = responsePSW.password;
                                    UsuariosApi.Update(usuario)
                                        .then((response) => {
                                            if (response !== 0) {
                                                notification.success({ message: 'Exito', description: 'Clave modificada', placement: 'bottomRight' });
                                                setWorking(false);
                                                AuthenticationHelper.logout(() => history.push("/login"));
                                            } else {
                                                notification.error({ message: 'Error', description: '¡No se pudo modificar la clave!', placement: 'bottomRight' });
                                                setWorking(false);
                                            }
                                        })
                                        .catch((error) => { setWorking(false); });

                                });
                            setWorking(false);
                        })
                        .catch((error) => { setWorking(false); });
                } else {
                    notification.error({ message: 'Error', description: '¡Las claves deben ser iguales!', placement: 'bottomRight' });
                }
            });
    }

    return (
        <Modal
            title={`Cambiar Clave`}
            width="60%"
            visible={props.visible}
            onOk={handleOk}
            onCancel={props.onCancel}
        >
            <Spin spinning={working}>
                <Form
                    {...FormConfig.DefaultLayout}
                    form={form}
                    name="formulario"
                    scrollToFirstError
                >
                    <ClaveFormItem name="Clave" label="Nueva Clave" rules={FormConfig.DefaultRules} />

                    <FormItem name="ConfirmacionClave" label="Confirmar Nueva Clave" rules={FormConfig.DefaultRules}>
                        <Input.Password />
                    </FormItem>

                    <Fragment >
                        <center>
                            <p><b>¡Su nueva clave debe contener como minimo 8 caracteres, ser alfanumerico y contener al menos una mayuscula!</b></p>
                            <p>Ejemplo: NUEVAclave678</p>
                        </center>
                    </Fragment>
                </Form>
            </Spin>
        </Modal>
    );
}

export default CambiarClaveModal;