import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ConfigProvider } from 'antd';
import esES from 'antd/es/locale/es_ES';

import 'moment/locale/es';
import moment from 'moment-timezone';
import { MomentConfig } from './shared/config/GlobalConfig';

moment.locale('es');
moment.tz.setDefault(MomentConfig.tz);

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={esES}> 
      <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
