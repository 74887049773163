
import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import { Route } from "react-router-dom";
import PresentacionForm from "./components/form/PresentacionForm";
import PresentacionList from "./components/list/PresentacionList";
import PresentacionView from "./components/view/PresentacionView";
 
const PresentacionesPage = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/`} component={ PresentacionList } />
      <Route exact path={`${path}/new`} component={ PresentacionForm } />
      <Route exact path={`${path}/view/:id/:cuit`} component={ PresentacionView } />
    </Switch>
  );
}

export default PresentacionesPage;