import React, { useEffect, useState } from "react";
import { Form, Input, Spin, Modal, InputNumber } from "antd";
import { FormConfig } from "../../../../shared/config/GlobalConfig";
import Utils from '../../../../shared/helpers/utils';
import EstablecimientosApi from "../../../establecimientos/api/EstablecimientosApi";
import ReferenciasApi from "../../../referencias/api/ReferenciasApi";
import ReferenciaSelectFormItem from "../../../../shared/components/referenciaSelectFormItem";
import EstablecimientoEmpresaSelectFormItem from "../../../../shared/components/establecimientoEmpresaSelectFormItem";
import CuitFormItem from '../../../../shared/components/cuitFormItem';
import CampoObligatorio from "../../../../shared/components/campoObligatorio";

const FormItem = Form.Item;

const AnexoIEmpresasTercerizadasModal = props => {
  const [establecimientosEmpresa, setEstablecimientosEmpresa] = useState([]);
  const [ciius, setCiius] = useState([]);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    EstablecimientosApi.GetEmpresasByCuit(props.cuit).then((response) => { setEstablecimientosEmpresa(response); })
    ReferenciasApi.GetTabla("PUBLICACIONES.CIIU").then(response => { setCiius(response); });
  }, []);

  useEffect(() => {
    if(props.visible === true){ form.resetFields(); }
  }, [props.visible]);

  const handleOk = () => {
    form.validateFields()
      .then(values => {
        props.onOk({ 
          Hash: props.empresa ? props.empresa.Hash : Utils.generateHash(), 
          ...values 
        });
      });
  }

  return (
    <Modal 
      title={`${props.empresa ? 'Editar' : 'Agregar'} Empresa Tercerizada`}
      width="80%"
      visible={ props.visible }
      onOk={ handleOk }
      onCancel={ props.onCancel }
    >
      <Spin spinning={ working }>
        <Form
          { ...FormConfig.DefaultLayout }
          form={ form }
          name="formulario"
          initialValues={{ ...props.empresa }}
          scrollToFirstError
        >
          { props.empresa?.IdEmpresaTercerizada ? <FormItem name="IdEmpresaTercerizada" label="Empresa Tercerizada" rules={ FormConfig.DefaultRules }>
            <Input disabled/>
          </FormItem> : '' }

          <EstablecimientoEmpresaSelectFormItem name="IdEstablecimientoEmpresa" label="Establecimiento Empresa" 
            rules={ FormConfig.DefaultRules } 
            establecimientosEmpresa={ establecimientosEmpresa }
          />

          <CuitFormItem name="Cuit" label="Cuit" rules={ [...FormConfig.DefaultRules] } />

          <ReferenciaSelectFormItem name="Ciiu" label="Ciiu" 
            rules={ FormConfig.DefaultRules } 
            tabla={ ciius }
          />

          <FormItem name="CantidadTrabajadores" label="Cantidad Trabajadores" rules={ FormConfig.DefaultRules }>
            <InputNumber min={ 0 } style={ { width: '100%' } }/>
          </FormItem>

          <CampoObligatorio/>
          
        </Form>
      </Spin>
    </Modal>
  );
}

export default AnexoIEmpresasTercerizadasModal;