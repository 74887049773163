import React, { useEffect, useState } from "react";
import { Space, Table } from "antd";
import parser from "../../../../shared/helpers/parser";
import EditButton from "../../../../shared/components/editButton";
import DeleteButton from "../../../../shared/components/deleteButton";
import Utils from "../../../../shared/helpers/utils";
import EstablecimientosApi from "../../../establecimientos/api/EstablecimientosApi";
import ReferenciasApi from "../../../referencias/api/ReferenciasApi";
import AuthenticationHelper from "../../../../shared/helpers/authenticationHelper";

const AnexoIEmpresasTercerizadasTable = props => {
  const [establecimientosEmpresa, setEstablecimientosEmpresa] = useState([]);
  const [ciius, setCiius] = useState([]);
  const permisosUsuario = AuthenticationHelper.getTipoUsuario() !== 'Operador';

  useEffect(() => {
    EstablecimientosApi.GetEmpresasByCuit(props.cuit).then((response) => { setEstablecimientosEmpresa(response); })
    ReferenciasApi.GetTabla("PUBLICACIONES.CIIU").then(response => { setCiius(response); });
  }, []);

  return (
    <Table
      size="small"
      columns={[
        { title: 'Id', dataIndex: 'IdEmpresaTercerizada', key: 'IdEmpresaTercerizada' },
        { title: 'Id Establecimiento Empresa', dataIndex: 'IdEstablecimientoEmpresa', key: 'IdEstablecimientoEmpresa' },
        {
          title: 'Establecimiento Empresa', dataIndex: 'IdEstablecimientoEmpresa', key: 'IdEstablecimientoEmpresa',
          render: text => Utils.getIdDesc(text, establecimientosEmpresa)
        },
        { title: 'Cuit', dataIndex: 'Cuit', key: 'Cuit' },
        {
          title: 'Ciiu', dataIndex: 'Ciiu', key: 'Ciiu',
          render: text => Utils.getIdDesc(text, ciius)
        },
        { title: 'Cantidad Trabajadores', dataIndex: 'CantidadTrabajadores', key: 'CantidadTrabajadores' },
        //{ title: 'Fecha Insert', dataIndex: 'FechaInsert', key: 'FechaInsert', render: text => <span>{ parser.date(text) }</span> },
        props.formMode ? {
          title: '', dataIndex: 'Actions', key: 'Actions',
          render: (text, record) => {
            return <Space size="small">
              {
                permisosUsuario ?
                  <>
                    <EditButton onClick={() => props.onEdit(record)} />
                    <DeleteButton onConfirm={ () => props.onRemove(record) }/>
                    {/* {!record.IdEmpresaTercerizada ? <DeleteButton onConfirm={() => props.onRemove(record)} /> : ''} */}
                  </> : null
              }
            </Space>
          }
        } : {},
      ]}
      dataSource={props.EmpresasTercerizadas}
      rowKey={(record, index) => index}
      pagination={{ hideOnSinglePage: true, pageSize: 10, showSizeChanger: false }}
    />
  );
}

export default AnexoIEmpresasTercerizadasTable;