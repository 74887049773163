import React, { useEffect, useState } from 'react';
import { Layout, Button, Row, Col, Popover, Tooltip, Divider, Space, notification, Spin, Card } from 'antd';
import { useHistory } from "react-router";
import AuthenticationHelper from '../../../../shared/helpers/authenticationHelper';
import { UserOutlined } from '@ant-design/icons';
import './AppHeader.scss';
import Avatar from 'antd/lib/avatar/avatar';
import AuthApi from '../../../auth/api/AuthApi';
import { AppConfig } from '../../../../shared/config/GlobalConfig';
import UsuariosApi from '../../../usuarios/api/UsuariosApi';
import CambiarClaveModal from '../../../../shared/components/cambiarClaveModal';

const { Header } = Layout;

const AppHeader = props => {
  let history = useHistory();
  const [working, setWorking] = useState(false);
  //const user = AuthenticationHelper.getUser();
  const [tipoUsuario, setTipoUsuario] = useState();
  const [showModal, setShowModal] = useState(false);


  useEffect(() => {
    UsuariosApi.TipoUsuario(AuthenticationHelper.getId()).then(response => { setTipoUsuario(response); });
  }, []);

  const logout = () => {
    AuthenticationHelper.logout(() => history.push("/login"));
  }

  const forceTicket = () => {
    setWorking(true);
    AuthApi.ForceTicket("WSE")
      .then((response) => {
        setWorking(false);
        if (response != '') {
          notification.success({ message: 'Exito', description: 'Ticket solicitado correctamente', placement: 'bottomRight' });
        } else {
          notification.error({ message: 'Error', description: '¡Error al solicitar ticket! Cerrar la pagina y abrir nuevamente.', placement: 'bottomRight' });
        }
      })
      .catch((error) => { setWorking(false); });
  }


  return (
    <>
      <Header className="app-layout-background"
        style={{
          padding: '0 15px',
          backgroundColor: window.location.href.indexOf('test') > -1 || window.location.href.indexOf('localhost') > -1 ? '#ffe58f' : '#fff'
        }}
      >
        <Row align="middle">

          <Col xs={12} sm={12} md={12} lg={12}>
            <h2 style={{ fontWeight: 100, margin: 0 }}>{AppConfig.siteName}</h2>
          </Col>

          <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'right' }}>
            <Space size="large">

              {window.location.href.indexOf('test') > -1 || window.location.href.indexOf('localhost') > -1
                ? <h2 style={{ fontWeight: 100, margin: 0 }}></h2>
                : ''}

              <Popover placement="bottomRight"
                content={
                  <div>
                    {
                      tipoUsuario !== "Operador" ?
                        <>
                          <Button style={{ textAlign: 'left', width: '100%' }}
                            type="text"
                            onClick={forceTicket}
                            loading={working}
                          >Solicitar Nuevo Ticket</Button>

                          <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                        </> : null
                    }
                    <Button style={{ textAlign: 'left', width: '100%' }}
                      type="text"
                      onClick={() => setShowModal(true)}
                    >Cambiar clave</Button>

                    <Divider style={{ marginTop: 8, marginBottom: 8 }} />

                    <Button style={{ textAlign: 'left', width: '100%' }}
                      type="text"
                      onClick={logout}
                    >Salir</Button>
                  </div>
                }
                trigger="click">
                <Tooltip title={ /*user*/ "Menu"} placement="left">
                  <Avatar size="large" style={{ backgroundColor: '#f15e21', cursor: 'pointer' }} icon={<UserOutlined />} />
                </Tooltip>
              </Popover>
            </Space>
          </Col>
        </Row>
        {
          showModal ?
            <>
              <CambiarClaveModal
                visible={showModal}
                onCancel={() => setShowModal(false)}
              />
            </> : null
        }
      </Header>
    </>
  );
}

export default AppHeader;
