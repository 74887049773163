import React from "react";
import { Form, Select } from "antd";
import { FormConfig } from "../config/GlobalConfig";
import Utils from "../helpers/utils";

const FormItem = Form.Item;
const { Option } = Select;

const EstablecimientoDeclaradoSelectForm = props => {
  return (
    <FormItem {...props}>
      <Select mode="multiple" allowClear placeholder="Seleccione uno o mas establecimientos" filterOption={FormConfig.DefaultSelectFilter} >
        {
          props.tabla.map(item => {
          let value = item.IdEstablecimientoEmpresa ? item.IdEstablecimientoEmpresa
            : '';
          let desc = item.DescripcionActividad ? item.DescripcionActividad
            : '';
          let establecimiento = Utils.getIdDesc(item.IdEstablecimientoEmpresa, props.establecimientos)
          return <Option key={value} value={value}>{`${desc} - ${establecimiento}` }</Option>
        })
        
        }
      </Select>
    </FormItem>
  );
};

export default EstablecimientoDeclaradoSelectForm;