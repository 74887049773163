import React, { useEffect, useState } from "react";
import { Form, Input, Spin, Modal } from "antd";
import { FormConfig } from "../../../../../shared/config/GlobalConfig";
import ReferenciasApi from "../../../../referencias/api/ReferenciasApi";
import Utils from "../../../../../shared/helpers/utils";
import ReferenciaSelectFormItem from "../../../../../shared/components/referenciaSelectFormItem";
import CampoObligatorio from "../../../../../shared/components/campoObligatorio";

const FormItem = Form.Item;

const AnexoVUtilizacionModal = props => {
  const [usosOrigen, setUsosOrigen] = useState([]);
  const [modosEmpleo, setModosEmpleo] = useState([]);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    ReferenciasApi.GetTabla("SVCC.TUSO_ORIGEN").then(response => { setUsosOrigen(response); });
    ReferenciasApi.GetTabla("SVCC.TMODO_EMPLEO").then(response => { setModosEmpleo(response); });
  }, []);

  useEffect(() => {
    if(props.visible === true){ form.resetFields(); }
  }, [props.visible]);

  const handleOk = () => {
    form.validateFields()
      .then(values => {
        props.onOk({ 
          Hash: props.utilizacion ? props.utilizacion.Hash : Utils.generateHash(), 
          ...values 
        });
      });
  }

  return (
    <Modal 
      title={`${props.utilizacion ? 'Editar' : 'Agregar'} Utilización`}
      width="60%"
      visible={ props.visible }
      onOk={ handleOk }
      onCancel={ props.onCancel }
    >
      <Spin spinning={ working }>
        <Form
          { ...FormConfig.DefaultLayout }
          form={ form }
          name="formulario"
          initialValues={{ ...props.utilizacion,
            IdSustanciaDeclarada: props.utilizacion?.IdSustanciaDeclarada ? props.utilizacion.IdSustanciaDeclarada : props.sustancia?.IdSustanciaDeclarada
          }}
          scrollToFirstError
        >
          { props.utilizacion?.IdUtilizacion ? <FormItem name="IdUtilizacion" label="Utilizacion" rules={ FormConfig.DefaultRules }>
            <Input disabled/>
          </FormItem> : '' }

          { props.sustancia?.IdSustanciaDeclarada ? <FormItem name="IdSustanciaDeclarada" label="Sustancia Declarada" rules={ FormConfig.DefaultRules }>
            <Input disabled/>
          </FormItem> : '' }

          <ReferenciaSelectFormItem name="UsoOrigen" label="Uso Origen" 
            rules={ FormConfig.DefaultRules } 
            tabla={ usosOrigen }
          />

          <ReferenciaSelectFormItem name="ModoDeEmpleo" label="Modo de Empleo" 
            /*rules={ FormConfig.DefaultRules } */
            tabla={ modosEmpleo }
          />

          <CampoObligatorio/>
          
        </Form>
      </Spin>
    </Modal>
  );
}

export default AnexoVUtilizacionModal;