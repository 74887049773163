import React, { useEffect, useState } from "react";
import { Form, Spin, Modal, Input, InputNumber } from "antd";
import Utils from "../../../../../shared/helpers/utils";
import { FormConfig } from "../../../../../shared/config/GlobalConfig";
import ReferenciasApi from "../../../../referencias/api/ReferenciasApi";
import ReferenciaSelectFormItem from "../../../../../shared/components/referenciaSelectFormItem";
import CampoObligatorio from "../../../../../shared/components/campoObligatorio";

const FormItem = Form.Item;

const AnexoVEstudioBiologicoEspecificoModal = props => {
  const [unidadesFrecuencia, setUnidadesFrecuencia] = useState([]);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    ReferenciasApi.GetTabla("SVCC.TFRECUENCIA").then(response => { setUnidadesFrecuencia(response); });
  }, []);

  useEffect(() => {
    if(props.visible === true){ form.resetFields(); }
  }, [props.visible]);

  const handleOk = () => {
    form.validateFields()
      .then(values => {
        props.onOk({ 
          Hash: props.estudio ? props.estudio.Hash : Utils.generateHash(), 
          ...values 
        });
      });
  }

  return (
    <Modal 
      title={`${props.estudio ? 'Editar' : 'Agregar'} Estudio Biologico Especifico`} 
      width="60%"
      visible={ props.visible }
      onOk={ handleOk }
      onCancel={ props.onCancel }
    >
      <Spin spinning={ working }>
        <Form
          { ...FormConfig.DefaultLayout }
          form={ form }
          name="formulario"
          initialValues={{ ...props.estudio,
            IdSustanciaDeclarada: props.estudio?.IdSustanciaDeclarada ? props.estudio.IdSustanciaDeclarada : props.sustancia?.IdSustanciaDeclarada,
          }}
          scrollToFirstError
        >
          { props.estudio?.IdEstudioBiologicoEspecifico ? <FormItem name="IdEstudioBiologicoEspecifico" label="Estudio Biologico Especifico" rules={ FormConfig.DefaultRules }>
            <Input disabled/>
          </FormItem> : '' }

          { props.sustancia?.IdSustanciaDeclarada ? <FormItem name="IdSustanciaDeclarada" label="Sustancia Declarada" rules={ FormConfig.DefaultRules }>
            <Input disabled/>
          </FormItem> : '' }

          <FormItem name="CantidadFrecuencia" label="Cantidad Frecuencia" rules={ FormConfig.DefaultRules }>
            <InputNumber min={ 0 } style={ { width: '100%' } }/>
          </FormItem>

          <ReferenciaSelectFormItem name="IdUnidadFrecuencia" label="Unidad Frecuencia" 
            rules={ FormConfig.DefaultRules } 
            tabla={ unidadesFrecuencia }
          />

          <FormItem name="AnalisisEstudiosComplementacion" label="Analisis Estudios Complementacion" rules={ FormConfig.DefaultRules }>
            <Input />
          </FormItem>

          <CampoObligatorio/>
          
        </Form>
      </Spin>
    </Modal>
  );
}

export default AnexoVEstudioBiologicoEspecificoModal;