
import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import { Route } from "react-router-dom";
import ReferenciaList from "./components/list/ReferenciaList";
 
const ReferenciasPage = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/`} component={ ReferenciaList } />
    </Switch>
  );
}

export default ReferenciasPage;