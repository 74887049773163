import React, { useEffect, useState } from "react";
import { Form, Spin, Modal, Input } from "antd";
import Utils from "../../../../../shared/helpers/utils";
import { FormConfig } from "../../../../../shared/config/GlobalConfig";
import CuitFormItem from '../../../../../shared/components/cuitFormItem';
import CampoObligatorio from "../../../../../shared/components/campoObligatorio";

const FormItem = Form.Item;

const AnexoVCompradorModal = props => {
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    
  }, []);

  useEffect(() => {
    if(props.visible === true){ form.resetFields(); }
  }, [props.visible]);

  const handleOk = () => {
    form.validateFields()
      .then(values => {
        props.onOk({ 
          Hash: props.comprador ? props.comprador.Hash : Utils.generateHash(), 
          ...values 
        });
      });
  }

  return (
    <Modal 
      title={`${props.comprador ? 'Editar' : 'Agregar'} Comprador`}
      width="60%"
      visible={ props.visible }
      onOk={ handleOk }
      onCancel={ props.onCancel }
    >
      <Spin spinning={ working }>
        <Form
          { ...FormConfig.DefaultLayout }
          form={ form }
          name="formulario"
          initialValues={{ ...props.comprador,
            IdSustanciaDeclarada: props.comprador?.IdSustanciaDeclarada ? props.comprador.IdSustanciaDeclarada : props.sustancia?.IdSustanciaDeclarada,
          }}
          scrollToFirstError
        >
          { props.comprador?.IdComprador ? <FormItem name="IdComprador" label="Comprador" rules={ FormConfig.DefaultRules }>
            <Input disabled/>
          </FormItem> : '' }

          { props.sustancia?.IdSustanciaDeclarada ? <FormItem name="IdSustanciaDeclarada" label="Sustancia Declarada" rules={ FormConfig.DefaultRules }>
            <Input disabled/>
          </FormItem> : '' }

          <CuitFormItem name="Cuit" label="Cuit" rules={ [...FormConfig.DefaultRules] } />

          <FormItem name="NombreComercial" label="Nombre Comercial" rules={ FormConfig.DefaultRules }>
            <Input />
          </FormItem>

          <CampoObligatorio/>
          
        </Form>
      </Spin>
    </Modal>
  );
}

export default AnexoVCompradorModal;