import React from "react";
import { Form, Input } from "antd";

const FormItem = Form.Item;

const CuitInput = props => {

  const cuilValidator = (cuil) => {
    if (cuil.length !== 11) {
      return false;
    }

    const [checkDigit, ...rest] = cuil
      .split('')
      .map(Number)
      .reverse();

    const total = rest.reduce(
      (acc, cur, index) => acc + cur * (2 + (index % 6)),
      0,
    );

    const mod11 = 11 - (total % 11);

    if (mod11 === 11) {
      return checkDigit === 0;
    }

    if (mod11 === 10) {
      return false;
    }

    return checkDigit === mod11;
  }

  return (
    <FormItem
      {...props}
      rules={[...props.rules, {

        validator(_, value) {
          const reg = /[0-9]*([0-9]*)?$/;
          if (value.length > 0 && value.length < 11) { return Promise.reject(new Error('El cuit debe tener 11 caracteres')) }
          if (cuilValidator(value)) {
            return (!isNaN(value) && reg.test(value)) || value === '' ? Promise.resolve() : Promise.reject(new Error('El cuit debe tener solo numeros'));
          } else {
            return Promise.reject(new Error('Cuit invalido'));
          }
        }
      }]}
    >
      <Input maxLength={11} style={{ width: '100%' }} />
    </FormItem>
  );
}

export default CuitInput;