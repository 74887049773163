import Request from "../../../shared/helpers/request";

const LoginApi = {
  Login: (email, password) => {
    return Request
      .get("auth/ticket/usuarios/" + email + "/clave?password=" + password)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error("fallo");
        throw error;
      });
  },
}

export default LoginApi;