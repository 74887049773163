import Request from "../../../shared/helpers/request";

const Usuario_EstablecimientoApi = {
    GetAll: () => {
        return Request
          .get('auth/ticket/usuarioEstablecimiento')
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            console.error(error);
            throw error;
          });
      },

      Get: id => {
        return Request
          .get('auth/ticket/usuarioEstablecimiento/' + id)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            console.error(error);
            throw error;
          });
      },

      GetIdsEstablecimientosUsuario: idUsuario => {
        return Request
          .get('auth/ticket/usuarioEstablecimiento/obtenerIds/' + idUsuario)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            console.error(error);
            throw error;
          });
      },

      Insert: (idUsuario, data) => {
        return Request
          .post('auth/ticket/usuarioEstablecimiento/agregar/' + idUsuario , data)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            console.error(error);
            throw error;
          });
      },

      Delete: id => {
        return Request
          .delete('auth/ticket/usuarioEstablecimiento/' + id)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            console.error(error);
            throw error;
          });
      },
    };

export default Usuario_EstablecimientoApi;