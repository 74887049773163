import Request from "../../../shared/helpers/request";

const EmailApi = {
  RecuperarClave: (email, clave) => {
    return Request
      .post("auth/ticket/email/recuperarclave/" + email +'/' + clave)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error("fallo");
        throw error;
      });
  },
}

export default EmailApi;