import React from "react";
import { Card, Space, Table } from "antd";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import AnexoVMedidasPreventivasDelPuestoTable from "./puestos_afectados/AnexoVMedidasPreventivasDelPuestoTable";
import AnexoVElementosProteccionPersonalTable from "./puestos_afectados/AnexoVElementosProteccionPersonalTable";
import DeleteButton from "../../../../../shared/components/deleteButton";
import EditButton from "../../../../../shared/components/editButton";
import parser from "../../../../../shared/helpers/parser";
import Utils from "../../../../../shared/helpers/utils";

const AnexoVPuestosAfectadosTable = props => {
  return (
    <Table
      size="small"
      columns={ [
        { title: 'Id', dataIndex: 'IdPuestoAfectado', key: 'IdPuestoAfectado' },
        //{ title: 'Sustancia Declarada', dataIndex: 'IdSustanciaDeclarada', key: 'IdSustanciaDeclarada' },
        { title: 'Puesto', dataIndex: 'IdPuesto', key: 'IdPuesto',
          render: text => Utils.getIdDesc(text, props.puestos)
        },
        { title: 'Descripcion Actividad', dataIndex: 'DescripcionActividad', key: 'DescripcionActividad' },
        { title: 'Informa Sobre Riesgos', dataIndex: 'InformaSobreRiesgos', key: 'InformaSobreRiesgos', render: text => text == true ? <CheckOutlined /> : <CloseOutlined /> },
        { title: 'Capacitacion Sobre Riesgos', dataIndex: 'CapacitacionSobreRiesgos', key: 'CapacitacionSobreRiesgos', render: text => text == true ? <CheckOutlined /> : <CloseOutlined /> },
        { title: 'Entrega Elementos de Proteccion', dataIndex: 'EntregaElementosDeProteccion', key: 'EntregaElementosDeProteccion', render: text => text == true ? <CheckOutlined /> : <CloseOutlined /> },
        { title: 'Descripcion Estudios', dataIndex: 'DescripcionEstudios', key: 'DescripcionEstudios' },
        { title: 'Licencia Especial', dataIndex: 'LicenciaEspecial', key: 'LicenciaEspecial', render: text => text == true ? <CheckOutlined /> : <CloseOutlined /> },
        //{ title: 'Fecha Insert', dataIndex: 'FechaInsert', key: 'FechaInsert', render: text => <span>{ parser.date(text) }</span> },
        props.formMode ? { title: '', dataIndex: 'Actions', key: 'Actions',
          render: (text, record) => {
            return <Space size="small">
              <EditButton onClick={ () => props.onEdit(record) }/>
              <DeleteButton onConfirm={ () => props.onRemove(record) }/>
              {/* { !record.IdPuestoAfectado ? <DeleteButton onConfirm={ () => props.onRemove(record) }/> : '' } */}
            </Space>
          }
        } : {},
      ] } 
      dataSource={ props.PuestosAfectados }
      rowKey={ (record, index) => index }
      pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
      expandable={ {
        expandedRowRender: record => <Card>
          <Card title="Medidas Preventivas del Puesto" type="inner" style={ { marginBottom: 16 } }>
            { record.MedidasPreventivasDelPuesto.length > 0
              ? <AnexoVMedidasPreventivasDelPuestoTable MedidasPreventivasDelPuesto={ record.MedidasPreventivasDelPuesto }/>
              : '' }
          </Card>
          <Card title="Elementos Proteccion Personal" type="inner" style={ { marginBottom: 16 } }>
            { record.ElementosProteccionPersonal.length > 0
              ? <AnexoVElementosProteccionPersonalTable ElementosProteccionPersonal={ record.ElementosProteccionPersonal }/>
              : '' }
          </Card>
        </Card>
      } }
    />
  );
}

export default AnexoVPuestosAfectadosTable;