import React, { useEffect, useState } from "react";
import { PageHeader, Table, Button, Tabs, Row, Col, List, Layout, Input, Spin, notification } from "antd";
import { SearchOutlined, ReloadOutlined } from '@ant-design/icons';
import ReferenciasApi from '../../api/ReferenciasApi';

const TabPane = Tabs.TabPane;
const { Content, Sider } = Layout;

const ReferenciaList = () => {
  const [listado, setListado] = useState([]);
  const [filteredListado, setFilteredListado] = useState([]);
  const [busqueda, setBusqueda] = useState([]);
  const [columnas, setColumnas] = useState([]);
  const [selectedTabla, setSelectedTabla] = useState([]);
  const [tabla, setTabla] = useState([]);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    loadListado();
  }, []);

  const loadListado = () => {
    setWorking(true);
    ReferenciasApi.GetListado()
      .then((response) => {
        setWorking(false);
        setListado(response);
        setFilteredListado(response);
      })
      .catch((error) => { 
        notification.error({ message: 'Error', description: '¡Los servicios web de la Super Intendencia están fuera de servicio!', placement: 'bottomRight' });
        setWorking(false); 
      });
  }

  const buildTabla = response => {
    let columns = [];
    if(response.length > 0){
      Object.keys(response[0]).map(key => {
        columns.push({ title: key, dataIndex: key, key: key });
      });
    }
    setColumnas(columns);
    setTabla(response);
  }

  const loadTabla = value => {
    setWorking(true);
    ReferenciasApi.GetTabla(value)
      .then((response) => {
        setWorking(false);
        setSelectedTabla(value);
        buildTabla(response);
      })
      .catch((error) => { setWorking(false); });
  }

  const handleSearch = e => {
    setBusqueda(e.target.value);
    if(e.target.value.length > 0){
      setFilteredListado(listado.filter(x => x.Alias.indexOf(e.target.value.toUpperCase()) > -1));
    }
    else{
      setFilteredListado(listado);
    }
  }

  return (
    <div style={ { backgroundColor: '#fff' } }>
      <PageHeader
        className="site-page-header"
        title="Referencias"
        extra={[
          <Button key="1" type="primary" icon={ <ReloadOutlined /> } 
            onClick={ () => { loadListado(); handleSearch({target:{value:''}}); } }
          >Refrescar</Button>,
        ]}
      />
      <div style={ { padding: 16, minHeight: 690 } }>
        <Spin spinning={ working }>
          <Row gutter={ 16 }>
            <Col span={ 8 }>
              <Input 
                allowClear
                placeholder="Buscar" 
                value={ busqueda }
                onChange={ handleSearch } 
                suffix={ <SearchOutlined /> }
                style={ { marginBottom: 8 } }
              />
              <List
                style={ { overflow: 'auto', maxHeight: 600 } }
                size="small"
                bordered
                dataSource={ filteredListado }
                renderItem={item => <List.Item 
                  style={ { cursor: 'pointer', backgroundColor: selectedTabla === item.Alias ? '#4db1b5' : '#fff' } }
                  onClick={ () => loadTabla(item.Alias) }>
                  <small>{ item.Alias }</small>
                </List.Item>}
              />
            </Col>

            <Col span={ 16 }>
              <h2>{ selectedTabla }</h2>
              { selectedTabla.length > 0
                ? <Table
                  style={ { overflow: 'auto' } }
                  size="small"
                  loading={ working }
                  columns={ columnas } 
                  dataSource={ tabla }
                  rowKey={ (record, index) => index }
                  pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
                />
                : '' }
            </Col>
          </Row>
        </Spin>
      </div>
    </div>
  );
}

export default ReferenciaList;

