import React, { useEffect, useState } from "react";
import { Form, Spin, Modal, Input } from "antd";
import ReferenciasApi from "../../../../../referencias/api/ReferenciasApi";
import Utils from "../../../../../../shared/helpers/utils";
import { FormConfig } from "../../../../../../shared/config/GlobalConfig";
import ReferenciaSelectFormItem from "../../../../../../shared/components/referenciaSelectFormItem";
import CampoObligatorio from "../../../../../../shared/components/campoObligatorio";

const FormItem = Form.Item;

const NominaExamenMedicoModal = props => {
  const [examenesMedicos, setExamenesMedicos] = useState([]);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    ReferenciasApi.GetTabla("SVCC.EXAMENMEDICO").then(response => { setExamenesMedicos(response); });
  }, []);

  useEffect(() => {
    if(props.visible === true){ form.resetFields(); }
  }, [props.visible]);

  const handleOk = () => {
    form.validateFields()
      .then(values => {
        props.onOk({ 
          Hash: props.examen ? props.examen.Hash : Utils.generateHash(), 
          ...values 
        });
      });
  }

  return (
    <Modal 
      title={`${props.examen ? 'Editar' : 'Agregar'} Examen Medico`}
      width="60%"
      visible={ props.visible }
      onOk={ handleOk }
      onCancel={ props.onCancel }
    >
      <Spin spinning={ working }>
        <Form
          { ...FormConfig.DefaultLayout }
          form={ form }
          name="formulario"
          initialValues={{...props.examen }}
          scrollToFirstError
        >
          { props.examen?.IdExamenDeclarado ? <FormItem name="IdExamenDeclarado" label="Examen Declarado" rules={ FormConfig.DefaultRules }>
            <Input disabled/>
          </FormItem> : '' }

          <ReferenciaSelectFormItem name="IdExamen" label="Examen" 
            rules={ FormConfig.DefaultRules } 
            tabla={ examenesMedicos }
          />

          <CampoObligatorio/>
          
        </Form>
      </Spin>
    </Modal>
  );
}

export default NominaExamenMedicoModal;