import React from "react";
import { Alert, Form, Input } from "antd";

const FormItem = Form.Item;

const ClaveFormItem = (props) => {
    return (
        <FormItem
          { ...props }
          rules={ [...props.rules, { 
            validator(_, value) {
              const reg = /^(?=.*\d)(?=.*[A-Z])\w{8,}$/;
              return (value.length>0 && reg.test(value))  ? Promise.resolve() : Promise.reject(new Error('La clave debe contener como minimo 8 caracteres, ser alfanumerico y contener al menos una mayuscula'));
            } 
          } ] }
        >
          <Input.Password minLength={ 8 } style={ { width: '100%' } } />
        </FormItem>
      );
}
    
export default ClaveFormItem;