import React from "react";
import { Form, Select } from "antd";
import { FormConfig } from "../config/GlobalConfig";
import AuthenticationHelper from "../helpers/authenticationHelper";

const FormItem = Form.Item;
const { Option } = Select;

const TipoUsuarioSelectFormItem = (props) => {

  const onChange = (idTipoUsuario) => {
    AuthenticationHelper.storeIdTipoUsuario(idTipoUsuario);
  }
    return (
        <FormItem { ...props }>
          <Select onChange={onChange} placeholder="Seleccione el tipo de usuario" filterOption={ FormConfig.DefaultSelectFilter }>
            { props.tabla.map(item => {
              let value = item.Id ? item.Id
                : '';
              let desc = item.Tipo ? item.Tipo
                : '';
              return <Option key={ value } value={ value }>{ `${value} - ${desc}` }</Option>
            }) }
          </Select>
        </FormItem>
      );
};

export default TipoUsuarioSelectFormItem;