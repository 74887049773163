import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { PageHeader, Button, Form, Card, Input, Spin, Collapse, Tooltip, notification, Modal } from "antd";
import { SaveOutlined, PlusCircleTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';
import { FormConfig } from "../../../../shared/config/GlobalConfig";
import AnexosVApi from "../../api/AnexosVApi";
import AnexoVSustanciasTable from "../view/AnexoVSustanciasTable";
import AnexoVSustanciaModal from "./AnexoVSustanciaModal";
import AuthenticationHelper from "../../../../shared/helpers/authenticationHelper";

const FormItem = Form.Item;
const { Panel } = Collapse;

const AnexoVForm = props => {
  const history = useHistory();
  const [sustancias, setSustancias] = useState([]);
  const [showSustanciaModal, setShowSustanciaModal] = useState(false);
  const [selectedSustancia, setSelectedSustancia] = useState(undefined);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();
  const idsEst = JSON.parse(AuthenticationHelper.getIdsEstablecimientosUsuario(AuthenticationHelper.getId()));
  const tipoUsuario = AuthenticationHelper.getTipoUsuario().toString();
  const permisosUsuario = AuthenticationHelper.getTipoUsuario() !== 'Operador';
  let sustanciasEstablecimientosUsuario = [];
  let datos = {
    "Cuit": "",
    "Sustancias": [],
    "Utilizaciones": [],
    "PuestosAfectados": [],
    "MedidasPreventivas": [],
    "ElementosDeProteccionPersonal": [],
    "EquiposRadiologicos": [],
    "Dosimetrias": [],
    "Proveedores": [],
    "Compradores": [],
    "EstudiosAmbientalesEspecificos": [],
    "EstudiosBiologicosEspecificos": [],
    "ResponsablesDeUso": [],
    "ResponsablesDeInstalacion": [],
    "EncargadosDeProteccionRadiologica": []
  }
  let eliminarDatos = false;

  useEffect(() => {
    datos.Cuit = props.match.params.cuit;
    AuthenticationHelper.storeDatosRemove(datos);

    if (props.location.pathname.indexOf('edit') > -1 && !props.location.anexoi && !props.location.anexov) {
      history.goBack();
    }
    if (props.location.pathname.indexOf('new') > -1 && !props.location.anexoi) {
      history.goBack();
    }
    if (props.location.anexov) {
      if (tipoUsuario == "Operador") {
        let totalSustancias = props.location.anexov.Sustancias;
        idsEst.forEach(itemId => {
          totalSustancias.forEach(sustancia => {
            if(sustancia.IdEstablecimientoEmpresa == itemId){
              sustanciasEstablecimientosUsuario.push(sustancia);
            }
          });
        });
        setSustancias(sustanciasEstablecimientosUsuario);
      } else {
        setSustancias(props.location.anexov.Sustancias);
      }
    }
  }, []);

  const onFinish = values => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: <span>¿Está seguro de querer guardar el Anexo V?</span>,
      onOk() {
        setWorking(true);
        let data = {
          //id: props.match.params.id, 
          ...values,
          Sustancias: sustancias,
        }   
        if (!props.location.anexov) {
          AnexosVApi.Save(data)
            .then(response => then(response))
            .catch(error => { setWorking(false); });
        }
        else {
          datos = AuthenticationHelper.getDatosRemove();

          Object.keys(datos).forEach(key => {
            if (Array.isArray(datos[key]) && datos[key].length > 0) {
              eliminarDatos = true;
            }
          });

          if (eliminarDatos) {
            AnexosVApi.Delete(datos)
            .then(response => then(response))
            .catch(error => { setWorking(false); });
          }

          AnexosVApi.Edit(data)
            .then(response => then(response))
            .catch(error => { setWorking(false); });
        }
      },
      onCancel() { },
    });

    const then = response => {
      setWorking(false);
      if (response == 'ok') {
        notification.success({ message: 'Exito', description: 'Anexo V guardado', placement: 'bottomRight' });
        AuthenticationHelper.removeDatosRemove();
        history.goBack();
      } else {
        notification.error({ message: 'Error', description: '¡No se pudo guardar el Anexo V!', placement: 'bottomRight' });
        notification.error({ message: 'Error', description: response, placement: 'bottomRight' });
      }
    }
  };

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <PageHeader
        className="site-page-header"
        title={`${props.location.anexov ? '' : 'Nuevo'} Anexo V`}
        //subTitle="Formulario"
        onBack={() => history.goBack()}
      />
      <div style={{ padding: 16, minHeight: 690 }}>
        <Spin spinning={working}>
          <Card>
            <Form
              {...FormConfig.DefaultLayout}
              form={form}
              name="formulario"
              onFinish={onFinish}
              initialValues={{ ...props.location.anexov }}
              scrollToFirstError
            >
              <FormItem name="Cuit" label="Cuit" rules={FormConfig.DefaultRules} initialValue={props.match.params.cuit}>
                <Input disabled />
              </FormItem>

              {props.location.anexov?.IdTipoOrganismo ? <FormItem name="IdTipoOrganismo" label="Tipo Organismo" rules={FormConfig.DefaultRules}>
                <Input disabled />
              </FormItem> : ''}

              {props.location.anexov?.IdOrganismo ? <FormItem name="IdOrganismo" label="Organismo" rules={FormConfig.DefaultRules}>
                <Input disabled />
              </FormItem> : ''}

              <Collapse style={{ marginBottom: 24 }}>
                {
                  permisosUsuario ?
                    <>
                      <Panel header="Sustancias" key="1"
                        extra={<Tooltip title="Agregar" placement="left"><PlusCircleTwoTone twoToneColor="#f15e21"
                          onClick={event => {
                            event.stopPropagation();
                            setSelectedSustancia(undefined);
                            setShowSustanciaModal(true);
                          }}
                        /></Tooltip>}
                      >
                        {sustancias.length > 0
                          ? <div style={{ overflow: 'auto', width: '100%' }}>
                            <AnexoVSustanciasTable formMode
                              cuit={props.match.params.cuit}
                              anexoi={props.location.anexoi}
                              Sustancias={sustancias}
                              onRemove={record => { 
                                setSustancias([...sustancias.filter(item => (item.IdSustanciaDeclarada !== record.IdSustanciaDeclarada))]); 
                                datos = AuthenticationHelper.getDatosRemove();
                                datos.Sustancias.push(record.IdSustanciaDeclarada);
                                AuthenticationHelper.storeDatosRemove(datos);
                                }}
                              onEdit={record => {
                                setSelectedSustancia(record);
                                setShowSustanciaModal(true);
                              }}
                            /></div>
                          : ''}
                      </Panel>
                    </> :
                    <>
                      <Panel header="Sustancias" key="1">
                        {sustancias.length > 0
                          ? <div style={{ overflow: 'auto', width: '100%' }}>
                            <AnexoVSustanciasTable formMode
                              cuit={props.match.params.cuit}
                              anexoi={props.location.anexoi}
                              Sustancias={sustancias}
                              onRemove={record => { 
                                setSustancias([...sustancias.filter(item => (item.IdSustanciaDeclarada !== record.IdSustanciaDeclarada))]); 
                                datos = AuthenticationHelper.getDatosRemove();
                                datos.Sustancias.push(record.IdSustanciaDeclarada);
                                AuthenticationHelper.storeDatosRemove(datos);
                                }}
                              onEdit={record => {
                                setSelectedSustancia(record);
                                setShowSustanciaModal(true);
                              }}
                            /></div>
                          : ''}
                      </Panel>
                    </>
                }

              </Collapse>

              <FormItem wrapperCol={{ span: 24 }} style={{ textAlign: 'right' }}>
                {
                  permisosUsuario ?
                    <>
                      <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>Guardar</Button>
                    </> : null
                }
              </FormItem>
            </Form>
            {
              showSustanciaModal ?
                <>
                  <AnexoVSustanciaModal
                    cuit={props.match.params.cuit}
                    sustancia={selectedSustancia}
                    anexoi={props.location.anexoi}
                    visible={showSustanciaModal}
                    onOk={sustancia => {
                      let arr = [...sustancias];
                      let idx = arr.findIndex(x => x.Hash ? x.Hash === sustancia.Hash : x.IdSustanciaDeclarada === sustancia.IdSustanciaDeclarada);
                      if (idx !== -1) { arr[idx] = sustancia; }
                      else { arr.push(sustancia); }
                      setSustancias([...arr]);
                      setShowSustanciaModal(false);
                    }}
                    onCancel={() => setShowSustanciaModal(false)}
                  />
                </> : null
            }
          </Card>
        </Spin>
      </div>
    </div>
  );
}

export default AnexoVForm;