import React from "react";
import { Form, Input, Select } from "antd";
import { FormConfig } from "../config/GlobalConfig";

const FormItem = Form.Item;
const { Option } = Select;

const ReferenciaSelectFormItem = props => {
  return (
    <FormItem { ...props }>
      <Select showSearch filterOption={ FormConfig.DefaultSelectFilter }>
        { props.tabla.map(item => {
          let value = item.Codigo ? item.Codigo
            : item.CIIU ? item.CIIU 
            : item.ciuo88 ? item.ciuo88 
            : '';
          let desc = item.Descripcion ? item.Descripcion
            : item.DESCRIPCION ? item.DESCRIPCION 
            : item.descripcion ? item.descripcion 
            : '';
          return <Option key={ value } value={ value }>{ `${value} - ${desc}` }</Option>
        }) }
      </Select>
    </FormItem>
  );
}

export default ReferenciaSelectFormItem;