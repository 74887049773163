
import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import { Route } from "react-router-dom";
import NominaForm from "./components/form/NominaForm";
//import PresentacionList from "./components/list/AnexoIList";
 
const NominasPage = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      {/*<Route exact path={`${path}/`} component={ PresentacionList } />*/}
      <Route exact path={`${path}/new/:cuit`} component={ NominaForm } />
      <Route exact path={`${path}/edit/:cuit`} component={ NominaForm } />
    </Switch>
  );
}

export default NominasPage;