import React, { useEffect, useState, Fragment } from "react";
import { PageHeader, Button, Spin, Card } from "antd";
import pdfAdmin from "../Manual_de_Usuario_Administrador.pdf";
import pdfOp from "../Manual_de_Usuario_Operador.pdf";
import { FilePdfOutlined } from '@ant-design/icons';
import AuthenticationHelper from "../../../shared/helpers/authenticationHelper";



const AyudaView = () => {  
  const [working, setWorking] = useState(false);
  let tipoUsuario = AuthenticationHelper.getTipoUsuario();

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <PageHeader
        className="site-page-header"
        title="Ayuda"
      />
      <div style={{ padding: 20 }}>
        <Spin spinning={working}>
          <Card>
            <p></p>
            <center>
            {
              tipoUsuario=="Operador" ? 
              <>
              <Button key="1" type="primary" icon={<FilePdfOutlined />}
              href={pdfOp}
              target="_blank"
              >
                Descargar Manual de Usuario
              </Button>
              </>: 
              tipoUsuario=="Administrador Presentacion" ?
              <>
              <Button key="1" type="primary" icon={<FilePdfOutlined />}
              href={pdfAdmin}
              target="_blank"
              >
                Descargar Manual de Usuario
              </Button>
              </>
              :
              <>
              <Button key="1" type="primary" icon={<FilePdfOutlined />}
              href={pdfAdmin}
              target="_blank"
              >
                Descargar Manual de Usuario Administrador
              </Button>
              <p></p>
              <Button key="1" type="primary" icon={<FilePdfOutlined />}
              href={pdfOp}
              target="_blank"
              >
                Descargar Manual de Usuario Operador
              </Button>
              </> 
            }
              
            </center>

            <div style={{ padding: 20 }}></div>
            <p></p>
            <h2>Datos de Contacto - Area: Prevención Art</h2>
            <p></p>
            <p><b>Dirección:</b>  24 de septiembre 946 - SM Tucuman </p>
            <p></p>
            <p><b>Teléfono:</b> (0381) 4977267 - Interno 1007 u Opción 6</p>
            <p></p>
            <p><b>Celular:</b> +54 9 3816 25-4400</p>
          </Card>
        </Spin>
      </div>
    </div>
  );
}

export default AyudaView;

