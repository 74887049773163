import React, { useCallback, useEffect, useState } from "react";
import { Form, Input, Spin, Modal, InputNumber } from "antd";
import { FormConfig } from "../../../../../shared/config/GlobalConfig";
import ReferenciasApi from "../../../../referencias/api/ReferenciasApi";
import Utils from "../../../../../shared/helpers/utils";
import CuitFormItem from '../../../../../shared/components/cuitFormItem';
import ReferenciaSelectFormItem from "../../../../../shared/components/referenciaSelectFormItem";
import CampoObligatorio from "../../../../../shared/components/campoObligatorio";

const FormItem = Form.Item;

const AnexoIResponsableModal = props => {
  const [contrataciones, setContrataciones] = useState([]);
  const [representaciones, setRepresentaciones] = useState([]);
  const [tiposProfResp, setTiposProfResp] = useState([]);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    ReferenciasApi.GetTabla("SVCC.TCONTRATACION").then((response) => { setContrataciones(response); })
    ReferenciasApi.GetTabla("SVCC.TREPRESENTACION").then((response) => { setRepresentaciones(response); })
    ReferenciasApi.GetTabla("SVCC.TTIPOPROFESIONALRESPONSABLE").then((response) => { setTiposProfResp(response); })
  }, []);

  useEffect(() => {
    if(props.visible === true){ form.resetFields(); }
  }, [props.visible]);

  const handleOk = () => {
    form.validateFields()
      .then(values => {
        props.onOk({ 
          Hash: props.responsable ? props.responsable.Hash : Utils.generateHash(), 
          ...values 
        });
      });
  }

  return (
    <Modal 
      title={`${props.responsable ? 'Editar' : 'Agregar'} Responsable`}
      width="60%"
      visible={ props.visible }
      onOk={ handleOk }
      onCancel={ props.onCancel }
    >
      <Spin spinning={ working }>
        <Form
          { ...FormConfig.DefaultLayout }
          form={ form }
          name="formulario"
          initialValues={{ ...props.responsable }}
          scrollToFirstError
        >
          { props.responsable?.IdResponsable ? <FormItem name="IdResponsable" label="Responsable" rules={ FormConfig.DefaultRules }>
            <Input disabled/>
          </FormItem> : '' }

          <CuitFormItem name="CuilCuit" label="Cuit/Cuil" rules={ [...FormConfig.DefaultRules] } />

          <ReferenciaSelectFormItem name="IdContratacion" label="Contratacion" 
            rules={ FormConfig.DefaultRules } 
            tabla={ contrataciones }
          />

          <ReferenciaSelectFormItem name="IdRepresentacion" label="Representacion" 
            rules={ FormConfig.DefaultRules } 
            tabla={ representaciones }
          />

          <ReferenciaSelectFormItem name="IdTipoProfesionalResponsable" label="Tipo Profesional Responsable" 
            rules={ FormConfig.DefaultRules } 
            tabla={ tiposProfResp }
          />

          <FormItem name="Matricula" label="Matricula" rules={ FormConfig.DefaultRules }>
            <Input />
          </FormItem>

          <FormItem name="CantHorasAsignadas" label="Horas Mensuales Asignadas" rules={ FormConfig.DefaultRules }>
            <InputNumber min={ 0 } style={ { width: '100%' } }/>
          </FormItem>

          <CampoObligatorio/>
          
        </Form>
      </Spin>
    </Modal>
  );
}

export default AnexoIResponsableModal;