export default class Utils {
 
  static generateHash = length => {
    length = length ? length : 10;
    let charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".match(/./g);
    let text = "";
    for (let i = 0; i < length; i++) {
      text += charset[Math.floor(Math.random() * charset.length)];
    }
    return text;
  }

  static getReferenciaArray = parent => {
    let arr = [];
    if(parent){
      Object.keys(parent).map(key => {
        if(Array.isArray(parent[key])){ arr = parent[key]; }
        else{ arr = this.getReferenciaArray(parent[key]); }
      });
    }
    return arr;
  }

  static getIdDesc = (id, tabla) => {
    if(!tabla){ return id; }

    let item = tabla.find(x => 
      x.Codigo == id ||
      x.CODIGOMUESTRA == id ||
      x.ID == id ||
      x.Establecimiento?.Codigo == id ||
      x.CIIU == id ||
      x.ciuo88 == id ||
      x.IdPuesto == id ||
      x.IdSector == id ||
      x.IdSustanciaDeclarada == id
    );

    if(item){
      if(item.CODIGOMUESTRA){ return `Cod Programa: ${item.CODIGOPROGRAMA} - Cod Muestra: ${item.CODIGOMUESTRA} - Nro Muestra: ${item.NROMUESTRA}` }
      if(item.ID){ return `${item.ID} - ${item.NOMBRE}` }
      if(item.Establecimiento && item.Establecimiento.Codigo){ return `${item.Establecimiento.Codigo} - ${item.Establecimiento.Descripcion}` }
      if(item.CIIU){ return `${item.CIIU} - ${item.DESCRIPCION}` }
      if(item.ciuo88){ return `${item.ciuo88} - ${item.descripcion}` }
      if(item.IdPuesto){ return `${item.IdPuesto} - ${item.Nombre}` }
      if(item.IdSector){ return `${item.IdSector} - ${item.Nombre}` }
      if(item.IdSustanciaDeclarada){ return `${item.IdSustanciaDeclarada} - ${item.NombreComercial}` }
      return `${item.Codigo} - ${item.Descripcion}`;
    }

    return id;
  }
}