import React, { useEffect, useState } from "react";
import { Space, Table } from "antd";
import DeleteButton from "../../../../../../shared/components/deleteButton";
import EditButton from "../../../../../../shared/components/editButton";
import ReferenciasApi from "../../../../../referencias/api/ReferenciasApi";
import Utils from "../../../../../../shared/helpers/utils";

const AnexoVElementosProteccionPersonalTable = props => {
  const [elementosProteccion, setElementosProteccion] = useState([]);

  useEffect(() => {
    ReferenciasApi.GetTabla("SVCC.TELEMENTOS_PROTECCION_PERSONAL").then(response => { setElementosProteccion(response); });
  }, []);

  return (
    <Table
      size="small"
      columns={ [
        { title: 'Id', dataIndex: 'IdElementoDeProteccion', key: 'IdElementoDeProteccion' },
        { title: 'Referencial de Elemento de Proteccion', dataIndex: 'IdReferencialDeElementoDeProteccion', key: 'IdReferencialDeElementoDeProteccion',
          render: text => Utils.getIdDesc(text, elementosProteccion)
        },
        props.formMode ? { title: '', dataIndex: 'Actions', key: 'Actions',
          render: (text, record) => {
            return <Space size="small">
              <EditButton onClick={ () => props.onEdit(record) }/>
              <DeleteButton onConfirm={ () => props.onRemove(record) }/>
              {/* { !record.IdElementoDeProteccion ? <DeleteButton onConfirm={ () => props.onRemove(record) }/> : '' } */}
            </Space>
          }
        } : {},
      ] } 
      dataSource={ props.ElementosProteccionPersonal }
      rowKey={ (record, index) => index }
      pagination={ { hideOnSinglePage: true, pageSize: 10, showSizeChanger: false } }
    />
  );
}

export default AnexoVElementosProteccionPersonalTable;