export const AppConfig = {
  siteName: <h3 className="lead letraCaja">Sistema Cancerígenos - CPA</h3>
};

export const FormConfig = {
  DefaultRules: [{required: true, message: 'Por favor ingrese un valor' }],
  DefaultLayout: {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  },
  DefaultSelectFilter: (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
};

export const MomentConfig = {
  tz: 'America/Argentina/Buenos_Aires',
};

export const GLOBAL_FORM_ITEM_LAYOUT = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};