import React from 'react';
import { Table } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import parser from '../../../../shared/helpers/parser';
import DeleteButton from '../../../../shared/components/deleteButton';

const NominaFilesTable = props => {
  const columns = [
    { title: '', dataIndex: '', align:'center', width: '5%',
      render: text => <LinkOutlined />
    },
    { title: 'Nombre',  dataIndex: 'name' },
    { title: 'Tamaño',  dataIndex: 'size', width: '20%', align:'center',
      render: text => <span>{ parser.parseFileSize(text) }</span>
    },
    { title: 'Tipo', dataIndex: 'type', width: '20%', align:'center' },
    { title: '', dataIndex: '', align:'center', width: '5%',
      render: (text, record) => {
        return props.showActions === true
        ? <DeleteButton hideConfirm={ true } onConfirm={ () => props.onDelete(record) }/>
        : ''
      }
    },
  ];

  return (
    <Table
      rowKey={ record => record.name }
      columns={ columns }
      dataSource={ props.files }
      pagination={ false }
      size="small"
    />
  );
}

export default NominaFilesTable;