
import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import { Route } from "react-router-dom";
import AnexoIForm from "./components/form/AnexoIForm";
//import PresentacionList from "./components/list/AnexoIList";
 
const AnexoIPage = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      {/*<Route exact path={`${path}/`} component={ PresentacionList } />*/}
      <Route exact path={`${path}/new/:cuit`} component={ AnexoIForm } />
      <Route exact path={`${path}/edit/:cuit`} component={ AnexoIForm } />
    </Switch>
  );
}

export default AnexoIPage;