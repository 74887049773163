import React, { Fragment } from 'react';
import { Layout } from 'antd';
import AppLeftMenu from './components/leftMenu/AppLeftMenu';
import AppHeader from './components/header/AppHeader';
import AppContent from "./components/content/AppContent";
import AppFooter from "./components/footer/AppFooter";
import { BrowserRouter as Router, Redirect, Route, Switch  } from "react-router-dom";
import AuthenticationHelper from '../../shared/helpers/authenticationHelper';
import './AppLayout.scss';
import LoginContainer from '../login/LoginContainer';

function AppLayout() {
  return (
    <Router>
      <Layout style={{ minHeight: "100vh" }}>
        <Switch>
          <Route path="/login">
            <LoginContainer />
          </Route>

          {/*<Route path="/identity"
            render={ ({location}) => { return AuthenticationHelper.isUserAuthenticated()
              ? <Fragment>
                <AppLeftMenu />
                <Layout className="app-layout">
                  <AppHeader />
                  <AppContent />
                  <AppFooter />
                </Layout>
              </Fragment>
              : <Redirect push to="/login" />;
            } }
          />*/}

          <Route path="/"
            render={ ({location}) => { return AuthenticationHelper.isSRTTicketStored()
              ? <Fragment>
                <AppLeftMenu />
                <Layout className="app-layout">
                  <AppHeader />
                  <AppContent />
                  <AppFooter />
                </Layout>
              </Fragment>
              : <Redirect push to="/login" />;
            } }
          />

          <Route path="*">
            <Redirect push to="/login" />
          </Route>

        </Switch>
      </Layout>
    </Router>
  );
}
export default AppLayout;