import React, { useEffect, useState } from "react";
import { Form, Spin, Modal, Input } from "antd";
import ReferenciasApi from "../../../../../referencias/api/ReferenciasApi";
import Utils from "../../../../../../shared/helpers/utils";
import { FormConfig } from "../../../../../../shared/config/GlobalConfig";
import ReferenciaSelectFormItem from "../../../../../../shared/components/referenciaSelectFormItem";
import CampoObligatorio from "../../../../../../shared/components/campoObligatorio";

const FormItem = Form.Item;

const AnexoVMedidaPreventivaDelPuestoModal = props => {
  const [medidasPreventivas, setMedidasPreventivas] = useState([]);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    ReferenciasApi.GetTabla("SVCC.TMEDIDASPREVENTIVAS").then(response => { setMedidasPreventivas(response); });
  }, []);

  useEffect(() => {
    if(props.visible === true){ form.resetFields(); }
  }, [props.visible]);

  const handleOk = () => {
    form.validateFields()
      .then(values => {
        props.onOk({ 
          Hash: props.medida ? props.medida.Hash : Utils.generateHash(), 
          ...values 
        });
      });
  }

  return (
    <Modal 
      title={`${props.medida ? 'Editar' : 'Agregar'} Medida Preventiva del Puesto`}
      width="60%"
      visible={ props.visible }
      onOk={ handleOk }
      onCancel={ props.onCancel }
    >
      <Spin spinning={ working }>
        <Form
          { ...FormConfig.DefaultLayout }
          form={ form }
          name="formulario"
          initialValues={{ ...props.medida }}
          scrollToFirstError
        >
          { props.medida?.IdMedidaPreventiva ? <FormItem name="IdMedidaPreventiva" label="Medida Preventiva" rules={ FormConfig.DefaultRules }>
            <Input disabled/>
          </FormItem> : '' }

          <ReferenciaSelectFormItem name="IdReferencialDeMedidasPreventivas" label="Referencial de Medidas Preventivas" 
            rules={ FormConfig.DefaultRules } 
            tabla={ medidasPreventivas }
          />

          <CampoObligatorio/>
          
        </Form>
      </Spin>
    </Modal>
  );
}

export default AnexoVMedidaPreventivaDelPuestoModal;