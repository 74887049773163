import React, { useEffect, useState } from "react";
import { Form, Input, Spin, Select, Collapse, Modal, Tooltip, Checkbox, notification } from "antd";
import { PlusCircleTwoTone } from '@ant-design/icons';
import AnexoVMedidasPreventivasDelPuestoTable from "../../view/sustancias/puestos_afectados/AnexoVMedidasPreventivasDelPuestoTable";
import AnexoVMedidaPreventivaDelPuestoModal from "./medidas_preventivas_puesto/AnexoVMedidaPreventivaDelPuestoModal";
import { FormConfig } from "../../../../../shared/config/GlobalConfig";
import Utils from "../../../../../shared/helpers/utils";
import AnexoVElementosProteccionPersonalTable from "../../view/sustancias/puestos_afectados/AnexoVElementosProteccionPersonalTable";
import AnexoVElementoProteccionPersonalModal from "./medidas_preventivas_puesto/AnexoVElementoProteccionPersonalModal";
import CampoObligatorio from "../../../../../shared/components/campoObligatorio";
import AuthenticationHelper from "../../../../../shared/helpers/authenticationHelper";

const FormItem = Form.Item;
const { Option } = Select;
const { Panel } = Collapse;

const AnexoVPuestoAfectadoModal = props => {
  const [medidasPreventivasDelPuesto, setMedidasPreventivasDelPuesto] = useState([]);
  const [elementosProteccionPersonal, setElementosProteccionPersonal] = useState([]);
  const [showMedidasPreventivasDelPuestoModal, setShowMedidasPreventivasDelPuestoModal] = useState(false);
  const [showElementoProteccionPersonalModal, setShowElementoProteccionPersonalModal] = useState(false);
  const [selectedMedidaPreventivaDelPuesto, setSelectedMedidaPreventivaDelPuesto] = useState(undefined);
  const [selectedElementoProteccionPersonal, setSelectedElementoProteccionPersonal] = useState(undefined);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
  }, []);

  useEffect(() => {
    if (props.visible === true) {
      form.resetFields();
      setMedidasPreventivasDelPuesto(props.puestoAfectado ? props.puestoAfectado.MedidasPreventivasDelPuesto : []);
      setElementosProteccionPersonal(props.puestoAfectado ? props.puestoAfectado.ElementosProteccionPersonal : []);
    }
  }, [props.visible]);

  const handleOk = () => {
    form.validateFields()
      .then(values => {
        props.onOk({
          Hash: props.puestoAfectado ? props.puestoAfectado.Hash : Utils.generateHash(),
          ...values,
          MedidasPreventivasDelPuesto: medidasPreventivasDelPuesto,
          ElementosProteccionPersonal: elementosProteccionPersonal,
        });
      })
      .catch(error => {
        notification.warning({ message: 'Error de validación', description: 'Revise los campos del formulario', placement: 'bottomRight' });
      });
  }

  return (
    <Modal
      title={`${props.puestoAfectado ? 'Editar' : 'Agregar'} Puesto Afectado`}
      width="80%"
      visible={props.visible}
      onOk={handleOk}
      onCancel={props.onCancel}
    >
      <Spin spinning={working}>
        <Form
          {...FormConfig.DefaultLayout}
          form={form}
          name="formulario"
          initialValues={{
            ...props.puestoAfectado,
            IdSustanciaDeclarada: props.puestoAfectado?.IdSustanciaDeclarada ? props.puestoAfectado.IdSustanciaDeclarada : props.sustancia?.IdSustanciaDeclarada,
            InformaSobreRiesgos: props.puestoAfectado ? props.puestoAfectado.InformaSobreRiesgos : false,
            CapacitacionSobreRiesgos: props.puestoAfectado ? props.puestoAfectado.CapacitacionSobreRiesgos : false,
            EntregaElementosDeProteccion: props.puestoAfectado ? props.puestoAfectado.EntregaElementosDeProteccion : false,
            LicenciaEspecial: props.puestoAfectado ? props.puestoAfectado.LicenciaEspecial : false,
          }}
          scrollToFirstError
        >
          <Collapse defaultActiveKey="1">
            <Panel header="Puesto Afectado" key="1">
              {props.puestoAfectado?.IdPuestoAfectado ? <FormItem name="IdPuestoAfectado" label="Puesto Afectado" rules={FormConfig.DefaultRules}>
                <Input disabled />
              </FormItem> : ''}

              {props.sustancia?.IdSustanciaDeclarada ? <FormItem name="IdSustanciaDeclarada" label="Sustancia Declarada" rules={FormConfig.DefaultRules}>
                <Input disabled />
              </FormItem> : ''}

              <FormItem name="IdPuesto" label="Puesto" rules={FormConfig.DefaultRules}>
                <Select showSearch filterOption={FormConfig.DefaultSelectFilter}>
                  {props.puestos?.map(puesto => {
                    return <Option key={puesto.IdPuesto} value={puesto.IdPuesto}>{`${puesto.IdPuesto} - ${puesto.Nombre}`}</Option>
                  })}
                </Select>
              </FormItem>

              <FormItem name="DescripcionActividad" label="Descripcion Actividad" rules={FormConfig.DefaultRules}>
                <Input />
              </FormItem>
              <FormItem name="InformaSobreRiesgos" label="Informa Sobre Riesgos" valuePropName="checked">
                <Checkbox />
              </FormItem>
              <FormItem name="CapacitacionSobreRiesgos" label="Capacitacion Sobre Riesgos" valuePropName="checked">
                <Checkbox />
              </FormItem>
              <FormItem name="EntregaElementosDeProteccion" label="Entrega Elementos de Proteccion" valuePropName="checked">
                <Checkbox />
              </FormItem>
              <FormItem name="DescripcionEstudios" label="Descripcion Estudios" rules={FormConfig.DefaultRules}>
                <Input />
              </FormItem>
              <FormItem name="LicenciaEspecial" label="Licencia Especial" valuePropName="checked">
                <Checkbox />
              </FormItem>
            </Panel>

            <Panel header="Medidas Preventivas Del Puesto" key="2" forceRender={true}
              extra={<Tooltip title="Agregar" placement="left"><PlusCircleTwoTone twoToneColor="#f15e21"
                onClick={event => {
                  event.stopPropagation();
                  setSelectedMedidaPreventivaDelPuesto(undefined);
                  setShowMedidasPreventivasDelPuestoModal(true);
                }}
              /></Tooltip>}
            >
              {medidasPreventivasDelPuesto.length > 0
                ? <div style={{ overflow: 'auto', width: '100%' }}>
                  <AnexoVMedidasPreventivasDelPuestoTable formMode
                    MedidasPreventivasDelPuesto={medidasPreventivasDelPuesto}
                    onRemove={record => {
                      setMedidasPreventivasDelPuesto([...medidasPreventivasDelPuesto.filter(item => (item.IdMedidaPreventiva !== record.IdMedidaPreventiva))]);
                      let datos = AuthenticationHelper.getDatosRemove();
                      datos.MedidasPreventivas.push(record.IdMedidaPreventiva);
                      AuthenticationHelper.storeDatosRemove(datos);
                      }}
                    onEdit={record => {
                      setSelectedMedidaPreventivaDelPuesto(record);
                      setShowMedidasPreventivasDelPuestoModal(true);
                    }}
                  /></div>
                : ''}
            </Panel>

            <Panel header="Elementos Proteccion Personal" key="3"
              extra={<Tooltip title="Agregar" placement="left"><PlusCircleTwoTone twoToneColor="#f15e21"
                onClick={event => {
                  event.stopPropagation();
                  setSelectedElementoProteccionPersonal(undefined);
                  setShowElementoProteccionPersonalModal(true);
                }}
              /></Tooltip>}
            >
              {elementosProteccionPersonal.length > 0
                ? <div style={{ overflow: 'auto', width: '100%' }}>
                  <AnexoVElementosProteccionPersonalTable formMode
                    ElementosProteccionPersonal={elementosProteccionPersonal}
                    onRemove={record => { 
                      setElementosProteccionPersonal([...elementosProteccionPersonal.filter(item => (item.IdElementoDeProteccionPersonal !== record.IdElementoDeProteccionPersonal))]); 
                      let datos = AuthenticationHelper.getDatosRemove();
                      datos.ElementosDeProteccionPersonal.push(record.IdElementoDeProteccionPersonal);
                      AuthenticationHelper.storeDatosRemove(datos);
                      }}
                    onEdit={record => {
                      setSelectedElementoProteccionPersonal(record);
                      setShowElementoProteccionPersonalModal(true);
                    }}
                  /></div>
                : ''}
            </Panel>
          </Collapse>

          <CampoObligatorio/>

        </Form>
        {
          showMedidasPreventivasDelPuestoModal ?
            <>
              <AnexoVMedidaPreventivaDelPuestoModal
                visible={showMedidasPreventivasDelPuestoModal}
                medida={selectedMedidaPreventivaDelPuesto}
                onOk={medida => {
                  let arr = [...medidasPreventivasDelPuesto];
                  let idx = arr.findIndex(x => x.Hash ? x.Hash === medida.Hash : x.IdMedidaPreventiva === medida.IdMedidaPreventiva);
                  if (idx !== -1) { arr[idx] = medida; }
                  else { arr.push(medida); }
                  setMedidasPreventivasDelPuesto([...arr]);
                  setShowMedidasPreventivasDelPuestoModal(false);
                }}
                onCancel={() => setShowMedidasPreventivasDelPuestoModal(false)}
              />
            </> : null
        }

        {
          showElementoProteccionPersonalModal ?
            <>
              <AnexoVElementoProteccionPersonalModal
                visible={showElementoProteccionPersonalModal}
                elemento={selectedElementoProteccionPersonal}
                onOk={elemento => {
                  let arr = [...elementosProteccionPersonal];
                  let idx = arr.findIndex(x => x.Hash ? x.Hash === elemento.Hash : x.IdElementoDeProteccion === elemento.IdElementoDeProteccion);
                  if (idx !== -1) { arr[idx] = elemento; }
                  else { arr.push(elemento); }
                  setElementosProteccionPersonal([...arr]);
                  setShowElementoProteccionPersonalModal(false);
                }}
                onCancel={() => setShowElementoProteccionPersonalModal(false)}
              />
            </> : null
        }

      </Spin>
    </Modal>
  );
}

export default AnexoVPuestoAfectadoModal;