import Request from "../../../shared/helpers/request";

const UsuariosApi = {
    GetAll: () => {
        return Request
          .get('auth/ticket/usuarios')
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            console.error(error);
            throw error;
          });
      },

      Get: id => {
        return Request
          .get('auth/ticket/usuarios/' + id)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            console.error(error);
            throw error;
          });
      },

      GetUltimoIdUsuario: id => {
        return Request
          .get('auth/ticket/usuarios/ultimoid')
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            console.error(error);
            throw error;
          });
      },

      GetByPresentacion: idPresentacion => {
        return Request
          .get('auth/ticket/usuarios/bypresentacion/' + idPresentacion)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            console.error(error);
            throw error;
          });
      },

      GetByEmail: email => {
        return Request
          .get('auth/ticket/usuarios/byemail/' + email)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            console.error(error);
            throw error;
          });
      },

      Insert: data => {
        return Request
          .post('auth/ticket/usuarios/agregar', data)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            console.error(error);
            throw error;
          });
      },

      Update: data => {
        return Request
          .put('auth/ticket/usuarios/actualizar', data)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            console.error(error);
            throw error;
          });
      },

      Delete: id => {
        return Request
          .delete('auth/ticket/usuarios/' + id)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            console.error(error);
            throw error;
          });
      },

      IsAdmin: id => {
        return Request
          .get('auth/ticket/usuarios/admin/' + id)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            console.error(error);
            throw error;
          });
      },

      TipoUsuario: id => {
        return Request
          .get('auth/ticket/usuarios/tipo/' + id)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            console.error(error);
            throw error;
          });
      },

      GetTipoUsuarios: () => {
        return Request
          .get('auth/ticket/usuarios/tipousuarios')
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            console.error(error);
            throw error;
          });
      },

    };

export default UsuariosApi;