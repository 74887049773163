import React, { useState, useEffect } from 'react';
import { Form, Input, Button, notification, Modal, Spin } from "antd";
import { FormConfig } from "../../../../shared/config/GlobalConfig";
import EmailApi from "../../api/EmailApi";
import UsuariosApi from "../../../usuarios/api/UsuariosApi";
import AuthApi from "../../../auth/api/AuthApi";

const FormItem = Form.Item;

function RecuperarClaveModal() {
    const [working, setWorking] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (modalIsOpen === true) {
            form.resetFields();
        }
    }, [modalIsOpen]);

    const onOk = () => {
        let emailUsuario = form.getFieldValue('emailRecuperar') ? form.getFieldValue('emailRecuperar') : null;
        if (emailUsuario !== null) {
            setWorking(true);
            UsuariosApi.GetByEmail(emailUsuario)
                .then(response => {
                    if (response) {
                        AuthApi.Decrypt(response.Clave)
                            .then((responseClave) => {
                                EmailApi.RecuperarClave(emailUsuario, responseClave.password)
                                    .then((response) => {
                                        setWorking(false);
                                        if (response == "Ok") {
                                            notification.success({ message: 'Exito', description: 'Se envió un correo con su clave actual al email ingresado.', placement: 'bottomRight' });
                                            setModalIsOpen(false);
                                        }
                                        else {
                                            notification.error({ message: 'Error', description: response, placement: 'bottomRight' });
                                        }
                                    })
                                    .catch((error) => {
                                        setWorking(false);
                                        notification.error({ message: 'Error', description: '¡No se pudo enviar el correo de recuperacion de clave!\n\nContactarse con el administrador.', placement: 'bottomRight' });
                                    });
                            })
                            .catch((error) => {
                                setWorking(false);
                                notification.error({ message: 'Error', description: '¡No se pudo enviar el correo de recuperacion de clave!\n\nContactarse con el administrador.', placement: 'bottomRight' });
                            });
                    }
                    else {
                        setWorking(false);
                        notification.error({ message: 'Error', description: '¡No existe nungun usuario con el email ingresado!', placement: 'bottomRight' });
                    }
                })
                .catch((error) => {
                    setWorking(false);
                    notification.error({ message: 'Error', description: '¡No se pudo enviar el correo de recuperacion de clave!\n\nContactarse con el administrador.', placement: 'bottomRight' });
                }); 
        }
    }

    return (
        <div>
            <p><a onClick={() => setModalIsOpen(true)}>¿Olvidó su clave?</a></p>
            <Modal
                title={'Recuperar Clave'}
                visible={modalIsOpen}
                onCancel={() => setModalIsOpen(false)}
                footer={[
                    <center>
                        <Button type="primary" onClick={onOk}>Enviar correo de recuperación</Button>
                    </center>
                ]}
            >
                <Spin spinning={working}>
                    <Form
                        {...FormConfig.DefaultLayout}
                        form={form}
                        name="formulario"
                    >
                        <FormItem label="Ingrese su email" name="emailRecuperar"
                            rules={[
                                { required: true, message: "Ingresa tu usuario por favor." },
                                { type: 'email', message: 'Por favor ingrese un email válido' }
                            ]}
                        >
                            <Input />
                        </FormItem>
                    </Form>
                </Spin>
            </Modal>
        </div>
    );
}

export default RecuperarClaveModal;




