import React, { useEffect, useState } from "react";
import { Form, Input, Spin, Select, Collapse, Modal, Tooltip, Checkbox, DatePicker } from "antd";
import { PlusCircleTwoTone } from '@ant-design/icons';
import { FormConfig } from "../../../../../shared/config/GlobalConfig";
import Utils from "../../../../../shared/helpers/utils";
import NominaExamenesMedicosTable from "../../view/trabajadores/actividades/NominaExamenesMedicosTable";
import moment from "moment";
import NominaExamenMedicoModal from "./actividades/NominaExamenMedicoModal";
import CampoObligatorio from "../../../../../shared/components/campoObligatorio";

const FormItem = Form.Item;
const { Option } = Select;
const { Panel } = Collapse;

const NominaActividadModal = props => {
  const [examenesMedicos, setExamenesMedicos] = useState([]);
  const [showExamenMedicoModal, setShowExamenMedicoModal] = useState(false);
  const [selectedExamenMedico, setSelectedExamenMedico] = useState(undefined);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();
  const [sustancias, setSustancias] = useState([]);

  useEffect(() => {

  }, []);

  useEffect(() => {
    if (props.visible === true) {
      form.resetFields();
      setExamenesMedicos(props.actividad ? props.actividad.ExamenesMedicos : []);
      setSustancias(props.sustancias.filter(x => x.IdEstablecimientoEmpresa == props.IdEstablecimientoEmpresa));
    }
  }, [props.visible]);

  const handleOk = () => {
    form.validateFields()
      .then(values => {
        props.onOk({
          Hash: props.actividad ? props.actividad.Hash : Utils.generateHash(),
          ...values,
          ExamenesMedicos: examenesMedicos,
        });
      });
  }

  return (
    <Modal
      title={`${props.actividad ? 'Editar' : 'Agregar'} Actividad`}
      width="80%"
      visible={props.visible}
      onOk={handleOk}
      onCancel={props.onCancel}
    >
      <Spin spinning={working}>
        <Form
          {...FormConfig.DefaultLayout}
          form={form}
          name="formulario"
          initialValues={{
            ...props.actividad,
            FechaInicioExposicion: props.actividad && props.actividad.FechaInicioExposicion ? moment(props.actividad.FechaInicioExposicion) : undefined,
            FechaFinExposicion: props.actividad && props.actividad.FechaFinExposicion ? moment(props.actividad.FechaFinExposicion) : undefined,
            Permanente: props.actividad ? props.actividad.Permanente : false,
            IdEstablecimientoEmpresa: props.actividad ? props.actividad.IdEstablecimientoEmpresa : props.IdEstablecimientoEmpresa
          }}
          scrollToFirstError
        >
          <Collapse defaultActiveKey="1">
            <Panel header="Actividad" key="1">
              {props.actividad?.IdActividad ? <FormItem name="IdActividad" label="Actividad" rules={FormConfig.DefaultRules}>
                <Input disabled />
              </FormItem> : ''}

              <FormItem name="IdEstablecimientoEmpresa" label="Establecimiento Empresa" rules={FormConfig.DefaultRules}>
                <Input disabled />
              </FormItem>

              <FormItem name="IdPuestoDeclarado" label="Puesto" rules={FormConfig.DefaultRules}>
                <Select showSearch filterOption={FormConfig.DefaultSelectFilter}>
                  {props.puestos?.map(puesto => {
                    return <Option key={puesto.IdPuesto} value={puesto.IdPuesto}>{`${puesto.IdPuesto} - ${puesto.Nombre}`}</Option>
                  })}
                </Select>
              </FormItem>

              <FormItem name="IdSectorDeclarado" label="Sector" rules={FormConfig.DefaultRules}>
                <Select showSearch filterOption={FormConfig.DefaultSelectFilter}>
                  {props.sectores?.map(sector => {
                    return <Option key={sector.IdSector} value={sector.IdSector}>{`${sector.IdSector} - ${sector.Nombre}`}</Option>
                  })}
                </Select>
              </FormItem>

              <FormItem name="Permanente" label="Permanente" valuePropName="checked">
                <Checkbox />
              </FormItem>

              <FormItem name="IdSustanciaDeclarada" label="Sustancia Declarada" /*rules={ FormConfig.DefaultRules }*/>
                <Select showSearch filterOption={FormConfig.DefaultSelectFilter}>
                  {sustancias?.map(sustancia => {
                    return <Option key={sustancia.IdSustanciaDeclarada} value={sustancia.IdSustanciaDeclarada}>{`${sustancia.IdSustanciaDeclarada} - ${sustancia.NombreComercial}`}</Option>
                  })}
                </Select>
              </FormItem>

              <FormItem name="FechaInicioExposicion" label="Fecha Inicio Exposicion" /*rules={ FormConfig.DefaultRules }*/>
                <DatePicker style={{ width: '100%' }} format={"DD/MM/YYYY"} />
              </FormItem>

              <FormItem name="FechaFinExposicion" label="Fecha Fin Exposicion" /*rules={ FormConfig.DefaultRules }*/>
                <DatePicker style={{ width: '100%' }} format={"DD/MM/YYYY"} />
              </FormItem>
            </Panel>

            <Panel header="ExamenesMedicos" key="2" forceRender={true}
              extra={<Tooltip title="Agregar" placement="left"><PlusCircleTwoTone twoToneColor="#f15e21"
                onClick={event => {
                  event.stopPropagation();
                  setSelectedExamenMedico(undefined);
                  setShowExamenMedicoModal(true);
                }}
              /></Tooltip>}
            >
              {examenesMedicos.length > 0
                ? <NominaExamenesMedicosTable formMode
                  ExamenesMedicos={examenesMedicos}
                  onRemove={record => { setExamenesMedicos([...examenesMedicos.filter(item => (item.Hash !== record.Hash))]); }}
                  onEdit={record => {
                    setSelectedExamenMedico(record);
                    setShowExamenMedicoModal(true);
                  }}
                />
                : ''}
            </Panel>
          </Collapse>

          <CampoObligatorio/>

        </Form>
        {
          showExamenMedicoModal ?
            <>
              <NominaExamenMedicoModal
                visible={showExamenMedicoModal}
                examen={selectedExamenMedico}
                onOk={examen => {
                  let arr = [...examenesMedicos];
                  let idx = arr.findIndex(x => x.Hash ? x.Hash === examen.Hash : x.IdExamenDeclarado === examen.IdExamenDeclarado);
                  if (idx !== -1) { arr[idx] = examen; }
                  else { arr.push(examen); }
                  setExamenesMedicos([...arr]);
                  setShowExamenMedicoModal(false);
                }}
                onCancel={() => setShowExamenMedicoModal(false)}
              />
            </> : null
        }

      </Spin>
    </Modal>
  );
}

export default NominaActividadModal;