import React from "react";
import { Layout } from "antd";

const { Footer } = Layout;

const AppFooter = () => {
  return (
    <Footer className="app-layout-background" style={{ textAlign: "center" }}>
      <h3 className="lead letraCaja">&copy; Desarrollado por CSI - CPA 2023 </h3>
    </Footer>
  );
}

export default AppFooter;
