import React from "react";
import { Descriptions, Table } from "antd";
import parser from "../../../../../../shared/helpers/parser";

const DescItem = Descriptions.Item;

const AnexoVDosimetriaView = props => {
  return (
    <Descriptions size="small" column={ 1 } bordered style={ { marginBottom: 16 } }>
      <DescItem label="Id"><b>{ props.Dosimetria.IdDosimetria }</b></DescItem>
      {/*<DescItem label="Id Equipo Radiologico"><b>{ props.Dosimetria.IdEquiporadiologico }</b></DescItem>*/}
      <DescItem label="Cantidad de Personal"><b>{ props.Dosimetria.CantidadDePersonal }</b></DescItem>
      <DescItem label="Tipo Dosimetria"><b>{ props.Dosimetria.TipoDosimetria }</b></DescItem>
      <DescItem label="Metodo Dosimetria"><b>{ props.Dosimetria.MetodoDosimetria }</b></DescItem>
      <DescItem label="Cuit Responsable"><b>{ props.Dosimetria.CuitResponsable }</b></DescItem>
      {/*<DescItem label="Fecha Insert"><b>{ parser.date(props.Dosimetria.FechaInsert) }</b></DescItem>*/}
    </Descriptions>
  );
}

export default AnexoVDosimetriaView;