import Request from "../../../shared/helpers/request";

const AnexosIApi = {
  Save: data => {
    return Request
      .post('anexoi', data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },
  Edit: data => {
    return Request
      .put('anexoi', data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },
  Delete: data => {
    return Request
      .delete('anexoi', data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }
}

export default AnexosIApi;