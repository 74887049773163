import React, { useState } from "react";
import { Form, Select } from "antd";
import { FormConfig } from "../config/GlobalConfig";
import AuthenticationHelper from "../helpers/authenticationHelper";
import PresentacionesApi from "../../pages/presentaciones/api/PresentacionesApi";

const FormItem = Form.Item;
const { Option } = Select;

const PresentacionSelectFormItem = (props) => {

  const onChange = (idPresentacion) => {  
    PresentacionesApi.GetTipoPresentacion(idPresentacion).then(response => { AuthenticationHelper.storeIdTipoPresentacion(response); });
    PresentacionesApi.GetPresentacion(idPresentacion).then(response => { AuthenticationHelper.storePresentacionSeleccionada(response);});
   }

  return (
    <FormItem {...props}>
      <Select showSearch onChange={onChange} placeholder="Seleccione una presentacion" filterOption={FormConfig.DefaultSelectFilter}>
        {props.tabla.map(item => {
          let value = item.Id ? item.Id
            : '';
          let desc = item.Cuit ? item.Cuit
            : '';
          return <Option  key={value} value={value}>{`${value} - ${desc}`}</Option>
        })}
      </Select>
    </FormItem>
  );
}

export default PresentacionSelectFormItem;