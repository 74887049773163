import React from "react";
import { Form, Select } from "antd";
import { FormConfig } from "../config/GlobalConfig";


const FormItem = Form.Item;
const { Option } = Select;

const TipoPresentacionSelectFormItem = (props) => {

    return (
        <FormItem { ...props }>
          <Select placeholder="Seleccione el tipo de presentacion" filterOption={ FormConfig.DefaultSelectFilter }>
            { props.tabla.map(item => {
              let value = item.Id ? item.Id
                : '';
              let desc = item.Tipo ? item.Tipo
                : '';
              return <Option key={ value } value={ value }>{ `${value} - ${desc}` }</Option>
            }) }
          </Select>
        </FormItem>
      );
};

export default TipoPresentacionSelectFormItem;